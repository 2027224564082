.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login_text .input-group-text {
  background-color: transparent !important;
  border: 1px solid #393939 !important;
}
.airlists .card-body {
  background: unset !important;
}
#sidebar .sidebar_ul {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.no_data_text {
  text-align: center;
  color: #fff;
}
.live-btn {
  background: #4b9a24 !important;
}
.upcoming-btn {
  background: rgb(244, 169, 71) !important;
}
.end-btn {
  background: red !important;
}
.airtitle {
  color: #fff;
}
.light_theme .airtitle {
  color: black;
  font-weight: 500;
}
.light_theme .startsin {
  font-weight: 500;
}
.airno {
  color: #fff;
}
.light_theme .airno {
  color: black;
  font-weight: 500;
}
.airpodsimg {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid black;
  padding: 6px;
  background-color: black;
}
.light_theme .airpodsimg {
  border: 1px solid #57a329;
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 49%);
}
.airh4 {
  color: #fff;
}
.airlists ul {
  padding-left: 0px !important;
  list-style-type: none;
  color: #fff;
  margin-top: 20px;
}
.light_theme .airlists ul {
  color: black;
}
.airlists ul li p:nth-child(1) {
  color: #ffff;
}
.light_theme .airlists ul li p:nth-child(1) {
  color: black;
  font-weight: 500;
}
.airlists ul li {
  border-bottom: 1px solid #80808047;
  margin-top: 14px;
}
.airlists ul li p {
  margin-bottom: 0px !important;
  padding-bottom: 8px;
}
.airlists ul li a {
  color: #57a42a;
  word-break: break-word;
}
.light_theme .airlists ul li a {
  font-weight: 500;
}
.airlists ul li a:hover {
  color: #e79f49;
}
.light_theme .no_data_text {
  color: #000 !important;
}

.badge_trend {
  position: absolute !important;
  top: -4px;
  right: 0px;
  padding-top: 9px !important;
  background-image: linear-gradient(
    101deg,
    #4b9a24 0%,
    #9cd947 100%
  ) !important;
}
.badge_trend_detail {
  position: absolute !important;
  top: -15px;
  right: -3px;
  padding-top: 6px !important;
  background-image: linear-gradient(
    101deg,
    #4b9a24 0%,
    #9cd947 100%
  ) !important;
}
.badge-kyc-rect.badge_trend {
  padding-top: 9px !important;
}
.badge-kyc-rect.badge_trend_detail {
  padding-top: 6px !important;
}

.conta_toppols .badge-kyc-rect.badge_trend {
  padding-right: 15px !important;
}

.loading.blk_lkevl_stripe {
  display: block !important;
}

.stripe {
  min-height: 10px;
  background-color: #babbbc;
  display: inline-block;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}
.loading {
  min-width: 100px;
  display: inline;
}
.loading .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.inside_span_a,
.inside_span_a:hover,
.inside_span_a:focus {
  color: #fff;
}

.light_theme .inside_span_a {
  color: #1f0c35;
}
.light_theme .inside_span_a:hover,
.light_theme .inside_span_a:focus {
  color: #fff;
}
.text_in_badge_a {
  color: #fff !important;
}
.badge-green-rect .text_in_badge_a {
  color: #000 !important;
}

.flex_badges_dv {
  display: flex;
  align-items: center;
  min-width: 200px;
}

@media only screen and (max-width: 575px) {
  .hide_mob_dd_men {
    display: none;
  }
  .dropdown_normal_menu_aco_to.dropdown_normal button {
    min-width: 50px !important;
    max-height: 30px;
    /* min-width: max-content !important; */
  }
  .dropdown_normal_menu_aco_to.dropdown_normal button {
    min-height: 30px;
    border-radius: 20px !important;
  }
  .dropdown_normal_menu_aco_to.dropdown_normal button img {
    position: absolute;
    left: -10px;
    min-width: 30px;
    max-width: 30px;
    top: 0px;
  }
  .dropdown_normal_menu_aco_to.dropdown_normal button.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid !important;
    border-right: 0.5em solid transparent !important;
    border-bottom: 0;
    position: absolute;
    border-left: 0.5em solid transparent !important;
    right: 10px !important;
  }
  header .get-started-btn.btn_ava_new {
    width: max-content !important;
    border-radius: 30px !important;
    padding: 2px 15px !important;
  }
  .mobile_btn_div_flex {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
  .mobile_btn_div_flex .span_hide_mob {
    display: none;
  }
  .flex_mob_blk_div.d-flex {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .flex_mob_blk_div.align_col_bkk {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .flex_mob_blk_div.align_col_bkk .flex_badges_dv {
    margin-bottom: 10px !important;
  }
}

.img_filter_white {
  filter: brightness(0) invert(1);
  max-width: 20px !important;
}

.mobile_btn_div_flex {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.light_theme .nav-menu .get-started-btn {
  color: #fff !important;
}

.light_theme .nav-menu .get-started-btn svg {
  fill: #fff !important;
}

/* .light_theme .nav-menu .get-started-btn .img_filter_white
{
  filter: unset !important;
} */

.light_theme .nav-menu .get-started-btn:hover {
  color: #57a42a !important;
  background: transparent !important;
}
.light_theme .nav-menu .get-started-btn:hover svg {
  fill: #000 !important;
}

.light_theme .nav-menu .get-started-btn:hover .img_filter_white {
  filter: brightness(0) invert(0) !important;
}
.lis_paren_side_cry {
  color: #fff;
  padding: 20px 0px;
}
.lis_paren_side_cry img {
  margin-right: 5px;
}
#sidebar .lis_paren_side_cry span {
  display: none;
}
#sidebar .lis_paren_side_cry {
  margin-left: 10px;
}
#sidebar.side_shrink .lis_paren_side_cry span {
  display: block;
}
#sidebar.side_shrink .lis_paren_side_cry {
  margin-left: 0px !important;
}
.lis_paren_side_cry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allocation {
  border-bottom: 1px solid #3f3f3f;
  padding-bottom: 8px;
}
.light_theme .allocation {
  border-bottom: 1px solid #3f3f3f57;
}
.tabless .pagination {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.tabless .table-bordered {
  border: 1px solid #101010;
}
.light_theme .tabless .table-bordered {
  border: 1px solid #ffffff;
}
.tabless thead {
  display: none;
}
.tabless .table tr:first-child {
  border-bottom: 1px solid #272727;
}
.tabless .table tr {
  background: #101010 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border-bottom: 1px solid #272727;
}
.tabless .table tr td:nth-child(2) {
  text-align: end;
}
.tabless .table tr td a {
  color: #57a42a;
  font-size: 13px;
  /* font-weight: 500; */
}
.tabless .table tr td a:hover {
  color: #e79f49;
}
.tabless .page-item.active .page-link {
  color: #fff;
  background-color: #55a229;
  border-color: #55a229;
}
.tabless .page-item.disabled .page-link {
  color: #fff;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #55a228;
  font-weight: 500;
}
.light_theme .tabless .page-item.disabled .page-link {
  color: black;
}
.tabless .page-link {
  color: #ffff;
  background-color: transparent;
  border: 1px solid #55a229;
}
.light_theme .tabless .page-link {
  color: black;
}
.tabless .page-link:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #55a229;
  border-color: #55a229;
}
.tabless #pageDropDown,
.react-bootstrap-table-pagination-total {
  display: none;
}

@media only screen and (min-width: 992px) {
  #sidebar.side_shrink .lis_paren_side_cry span {
    display: none;
  }
  #sidebar.side_shrink .lis_paren_side_cry {
    margin-left: 10px !important;
  }
  #sidebar .lis_paren_side_cry span {
    display: block;
  }
  #sidebar .lis_paren_side_cry {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 575px) {
  .tabless .pagination {
    font-size: 9px;
  }
  .listed .d-flex {
    display: unset !important;
  }
  .listed ul li {
    border-bottom: 0px solid #80808047;
    margin-top: 14px;
  }
  .tabless .table td,
  .table th {
    padding: 0.45rem;
  }
}
#sidebar .lis_paren_side_cry span {
  font-size: 13px !important;
}

.light_theme .lis_paren_side_cry {
  color: #000;
}
.light_theme .lis_paren_side_cry img {
  filter: brightness(0) invert(0);
}

.exch_icon_re_he {
  max-width: 15px !important;
}
/* @media only screen and (min-width:768px)
{
  .btn_exc_align_os
  {
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
    min-width: 215px;
  }
} */
.token_ig_sel {
  max-width: 20px !important;
}

.dropdown_normal_menu_aco_to.dropdown_normal button.dropdown-toggle::after {
  content: "\f078";
  font: normal normal normal 14px/1 FontAwesome;
  border: none !important;
  top: 8px !important;
  position: absolute;
  right: 10px !important;
}

.dropdown_normal_menu_aco_to.dropdown_normal button img {
  position: absolute;
  left: -10px;
  min-width: 30px;
  max-width: 30px;
  top: 0px;
  border-radius: 50%;
}
.dropdown_normal_menu_aco_to.dropdown_normal button {
  padding: 0px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 20px !important;
}

.dropdown_normal_menu_aco_to.dropdown_normal button span {
  padding-left: 15px !important;
}
.dropdown_normal_menu.dropdown_normal_menu_new_co .dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #222;
}
.dropdown_normal_menu.dropdown_normal_menu_new_co {
  min-width: 250px !important;
}
.dropdown_normal_menu.dropdown_normal_menu_new_co .dropdown-item:last-child {
  border-bottom: none !important;
}
.light_theme .dropdown_normal_menu.dropdown_normal_menu_new_co {
  border-color: #ccc !important;
}
.light_theme .dropdown_normal_menu.dropdown_normal_menu_new_co .dropdown-item,
.light_theme .dropdown_normal_menu_aco_to.dropdown_normal button {
  border-color: #ccc !important;
}
@media only screen and (max-width: 991px) {
  .dropdown_normal_menu.dropdown_normal_menu_new_co {
    margin-top: 10px !important;
  }
}

.dropdown_normal_menu.dropdown_normal_menu_new_co {
  margin-top: 10px !important;
}
.currency_cho_card,
.currency_cho_card.currency_badge {
  padding: 20px 8px !important;
}
.currency_cho_card img {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  display: block;
  margin: 0 auto;
}
.currency_cho_card.currency_badge {
  background-color: #0a0a0a;
  border-radius: 3px !important;
}
.currency_cho_card.currency_badge:hover {
  background-color: #373737;
}
.currency_cho_card p {
  color: #fff !important;
  margin-top: 10px;
}
.light_theme .currency_cho_card p {
  color: #000 !important;
}
.light_theme .currency_cho_card.currency_badge:hover {
  background-color: #f7f7f7 !important;
}

#sidebar {
  display: flex;
  flex-direction: column;
}

#sidebar .sidebar_ul {
  flex: 1 1;
  overflow-y: auto;
  max-height: unset !important;
}
#sidebar .footer_theme_div {
  position: unset !important;
}

@media only screen and (max-width: 991px) {
  #sidebar .sidebar_ul {
    max-height: unset !important;
  }
}
.flke_align_btn_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.affilair_table {
  background-color: transparent !important;
}
.affilair_table th,
.affilair_table td {
  color: #fff !important;
  font-size: 13px !important;
  text-align: center;
}
.light_theme .affilair_table th,
.light_theme .affilair_table td {
  color: #000 !important;
}
.custom_tooltip_btn {
  height: 15px;
  width: 15px;
  display: flex !important;
  justify-content: center;
  /* color: black !important; */
  align-items: center;
  border-radius: 50% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-left: 3px;
  border: none !important;
}
.light_theme .custom_tooltip_btn {
  background-color: transparent !important;
  border: 1px solid #393939 !important;
}
.dark_theme .custom_tooltip_btn {
  background-color: #393939 !important;
  color: white;
}

.dark_theme .tooltip .tooltip-inner {
  background-color: white !important;
  color: black !important;
}

.light_theme .tooltip .tooltip-inner {
  background-color: white !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black !important;
}

@media (max-width: 480px) {
  .tooltip .tooltip-inner {
    /* position: relative !important;
    right: 50% !important;
    bottom: 50px !important; */
    /* top: -70px; */
    /* display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 100px !important;
    width: 100px !important; */
  }
  .white_list .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
  .soft_cap .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 30px !important;
  }
  .hard_cap .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
  .min_rewards .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 40px !important;
  }
  .max_rewards .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 40px !important;
  }
  .affiliate_percentage .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 40px !important;
  }
  .minimum_buy .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
  .maximum_buy .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
}

.tooltip_content.show
{
  opacity: 1 !important;
}
.tooltip .arrow {
  display: none !important;
}


@media only screen and (max-width:319px)
{
  .text_row_oneline .desc_grey_txt, .text_row_oneline > span
  {
    min-width: 100px !important;
  }
  .white_list .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
  .soft_cap .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 30px !important;
  }
  .hard_cap .tooltip-inner {
    position: relative !important;
    right: 50% !important;
    bottom: 50px !important;
  }
  .min_rewards .tooltip-inner {
    position: relative !important;
    right: 80% !important;
    bottom: 40px !important;
  }
  .max_rewards .tooltip-inner {
    position: relative !important;
    right: 80% !important;
    bottom: 40px !important;
  }
  .affiliate_percentage .tooltip-inner {
    position: relative !important;
    right: 80% !important;
    bottom: 40px !important;
  }
  .minimum_buy .tooltip-inner {
    position: relative !important;
    right: 60% !important;
    bottom: 50px !important;
  }
  .maximum_buy .tooltip-inner {
    position: relative !important;
    right: 75% !important;
    bottom: 50px !important;
  }
}