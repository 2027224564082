@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.sociallists {
  text-align: center;
}
.sociallists svg {
  color: #fff;
  font-size: 25px;
  margin-left: 10px;
  margin-top: 10px;
}
.sociallists svg:hover {
  /* color: #7dc13a; */
  filter: drop-shadow(2px 4px 6px #4b9a24);
}
.allcardlist .card_bg {
  height: 190px !important;
  min-height: 190px !important;
  max-height: 190px !important;
}

:root {
  --color: 255, 71, 84;
  --cursor-size: 30px;
}
.custom-cursor {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;

  width: var(--cursor-size);
  height: var(--cursor-size);

  border: calc(var(--cursor-size) / 30) solid #fff;
  border-radius: 50%;

  animation: cursor 800ms infinite alternate ease-in-out;
  pointer-events: none;
}

.custom-cursor::before {
  content: "";
  display: block;
  width: calc(var(--cursor-size) / 2);
  height: calc(var(--cursor-size) / 2);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: calc(var(--cursor-size) / 6) solid rgba(var(--color), 0.5);

  border-radius: 50%;

  animation: cursor-before 800ms infinite alternate ease-in-out;
}

.custom-cursor.click {
  animation: cursor-click 800ms normal ease-in-out;
}

@keyframes cursor {
  from {
    transform: scale(1);
    border-color: #fff;
  }

  to {
    transform: scale(1.5);
    border-color: rgb(var(--color));
  }
}

@keyframes cursor-before {
  from {
    transform: translate(-50%, -50%) scale(1);
    border-color: rgba(var(--color), 0.5);
  }

  to {
    transform: translate(-50%, -50%) scale(1.5);
    border-color: rgba(var(--color), 0.75);
  }
}

@keyframes cursor-click {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(2.5);
    border-color: rgb(var(--color));
  }
}

html,
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  height: 100%;
}
a {
  text-decoration: none !important;
}

html,
body {
  scroll-behavior: smooth;
}

a {
  outline: none !important;
}

/* INNER PAGE CSS */
.get-started-btn {
  font-size: 13px;
  font-weight: 400;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  /* transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s; */
  transition: 0.5s;
  height: 32px;
  padding: 0px 16px;
  border: 1px solid #5da425;
  /* background-color: rgb(76, 84, 155); */
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box;
  color: white;
  /* border: 0px; */
  border-radius: 165px;
  box-shadow: rgb(149 149 187 / 40%) 0px -1px 0px 0px inset;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.get-started-btn.rounded_btn_wal {
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  padding: 2px !important;
}
.get-started-btn.rounded_btn_wal svg {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
}
.get-started-btn svg {
  color: #fff !important;
  fill: #fff !important;
}

.get-started-btn:hover {
  /* background: linear-gradient(70deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%) !important; */
  /* background: transparent linear-gradient(131deg, #9CD947 0%, #4B9A24 100%) 0% 0% no-repeat padding-box; */

  /* border: 1px solid var(--textblue) !important; */
  /* color:#fff; */
  /* box-shadow: 0px 0px 12px #4b9a24a3; */
  transition: 0.5s;
  background: transparent;
  border: 1px solid #57a329;
  color: #69e01a !important;
}
.live-btn:hover {
  color: #fff !important;
}
.get-started-btn:hover svg {
  /* fill:#69e01a !important; */
  fill: #fff !important;
}
/* ....................v.................. */
:root {
  --textblue: #16f9b2;
  --themeorange: #e78638;
  /* --teme-color:#4c549c; */
}
.form_sec.ongoing_sec a.nav-link.active,
.form_sec.ongoing_sec a.nav-link p {
  color: var(--textblue) !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.form_sec.ongoing_sec .nav-link.active {
  background-image: linear-gradient(45deg, transparent, transparent) !important;
  background: transparent !important;
  border-bottom: 4px solid #4e9d66 !important;
  font-weight: 600 !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  border-bottom: 4px solid #f6f7ff !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  background: rgb(246, 247, 255) !important;
}
.form_sec.ongoing_sec .nav-link.active p {
  font-weight: 600 !important;
}
.form_sec.ongoing_sec .nav-link:hover {
  background-image: linear-gradient(45deg, transparent, transparent) !important;
}
.cardimg img {
  height: 150px;
  width: 100%;
}
.projects .card-body {
  padding: 0;
}
.rate {
  background: #f2f3f9;
  border-radius: 20px;
}
.rate .desc_grey_txt {
  color: var(--textblue) !important;
}
.text-gray {
  color: gray;
}
.font_13 {
  font-size: 13px !important;
}
.green_txt_big {
  color: #eb9f51;
  font-size: 15px !important;
}
.light_theme .green_txt_big {
  color: #55a228;
  font-weight: 600;
}

.orangetext {
  color: var(--themeorange) !important;
}
.text-blue {
  color: var(--textblue) !important;
}
.cardhead {
  font-weight: 600;
}
.green_txt {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
}
.rate .desc_grey_txt {
  color: var(--textblue) !important;
  font-weight: 700;
}
.profimg {
  position: relative;
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.light_theme .profimg {
  background: #fff;
  border: 1px solid #509e26db;
  box-shadow: 0 3px 10px rgb(0 0 0 / 64%);
}
.profimg .tokenimg {
  position: absolute;
  min-width: 35px !important;
  min-height: 35px !important;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: 0;
}
.infoimg img {
  width: 100%;
  border-radius: 10px;
}
.get-started-btn-fill.greenbtn {
  background: #519c67 !important;
  border-color: #519c67 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.greenbtn:hover {
  color: #fff !important;
  border-color: #519c67 !important;
}

.get-started-btn-fill.purplebtn {
  background: #16f9b2 !important;
  border-color: #16f9b2 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.purplebtn:hover {
  color: #fff !important;
  border-color: #16f9b2 !important;
}
.lightblue {
  background: #f6f7ff !important;
}
.input-group input.form-control::placeholder {
  color: #000 !important;
}
.input-group input::placeholder {
  font-size: 12px !important;
}
.ongoing_sec.proj_bg .card {
  height: 100%;
  border-radius: 10px !important;
}
/* .proj_bg{
  background: #f6f7ff;
} */
.border-bot div#header {
  border-bottom: 1px solid #d4d1d1;
}
.ongoing_sec.form_sec .img_card_outer {
  background: white;
}

@media only screen and (max-width: 575px) {
  .link_wallet_new_in_mobile.link_wallet_new {
    width: unset !important;
    border-radius: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .affiliate_progrma_tilte {
    font-size: 16px !important;
  }
  .airtitle {
    font-size: 12px;
  }
  .middile_dark,
  .middile_light {
    display: none !important;
  }
  .flex_row_cen {
    display: block !important;
  }
  .block_link_new_blue.pl-1 {
    display: block !important;
    padding-left: 0px !important;
  }
  .btn_lock_info_div .get-started-btn {
    display: block !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }
  .btn4_div {
    display: block !important;
  }

  .btn4_div .get-started-btn {
    width: 100%;
  }
  .dark_theme .row_add_remove .input-groups .input-group input {
    font-size: 10px !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .dark_theme .row_add_remove .input-groups .input-group input::placeholder {
    font-size: 10px !important;
  }
  .chart_donut_res,
  .chart_donut_res .apexcharts-canvas {
    max-width: 150px !important;
    width: 150px !important;
  }
  .input-groups .input-group.input_grp_profile_url .input-group-prepend,
  .input-groups .input-group.input_grp_profile_url input {
    /* width: 100% !important; */
  }
  .modal-content .icon_align_border {
    margin-left: auto;
    margin-right: auto;
  }
  .btn_sec_mob_res .get-started-btn {
    font-size: 10px !important;
  }
  .h3_res {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .flex_cont_end_flex {
    justify-content: flex-end;
  }
  .dblock_mob.d-flex {
    display: block !important;
    width: 100% !important;
  }
  .searc_style_1 {
    max-width: 100% !important;
  }
  .mt_18_px {
    margin-top: 0px !important;
  }
  /* .dblock_mob.d-flex>div
  {
  margin-bottom: 10px !important;
  } */
  .modal-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .logo_img_sm {
    max-width: 155px !important;
    width: 128px !important;
  }
  .whole_sec .prof-name {
    font-size: 25px !important;
  }
  .font_20 {
    font-size: 14px !important;
  }
  .coimg_soon_bg {
    background-size: cover, 100% !important;
    background-position: top, center;
  }
  .coimg_soon_center img {
    max-width: 150px !important;
  }
  .logo_img_sm {
    max-width: 155px;
  }
  .whole_sec {
    /* padding-top: 200px !important; */
  }
  .nav_parnt {
    flex-direction: column;
  }
  .nav_parnt_2,
  .nav_parnt_1 {
    /* display: flex;
    align-items: center;
    margin-top: 20px; */
  }
  .btn_row {
    flex-wrap: wrap !important;
  }
  .btn_row button {
    width: calc(50% - 1rem) !important;
  }
  .tab_div .nav-pills .nav-link {
    /* margin-right: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 14px !important; */
    margin-right: 1px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;
  }
  .header_flex {
    flex-direction: column;
  }
  .nav_parnt {
    /* margin-top: 20px !important; */
  }
  .inner_card {
    background-size: cover !important;
  }

  .grid_view {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple-new {
    margin-top: 20px;
  }
}

.banner_title {
  font-size: 48px !important;
  font-weight: 600;
  line-height: 1.1;
  color: rgb(76, 84, 155);
  margin-bottom: 24px;
}
.hr_yellow {
  border-top: 1px solid rgb(231, 227, 235) !important;
  width: 100% !important;
}
.banner_subtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.single_sale_card p.text-white span,
.single_sale_card p.white_txt_sm span {
  font-size: 17px;
}
p.word_brk_addrs.text-white {
  font-size: 17px !important;
  color: #6a6a6a !important;
}

.flex_cen_col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav_parnt_2 {
    display: flex;
    align-items: center;
  }
  .btn-purple {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .grid_view {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple {
    margin-top: 20px;
  }
  .grid_view .btn-purple-new {
    margin-top: 20px;
  }
}
.footer {
  margin-top: -50px;
}
/* inner css */

.arro_change .fa-angle-down:before {
  content: "\f106";
}

/* Modal css */
.modal-dialog .modal-content {
  /* background: linear-gradient(111.68deg, rgb(21, 2, 54) 0%, rgb(21, 2, 54) 100%); */
  background: #101010 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #4b9a24;
  border-radius: 30px !important;
  /* box-shadow: 0px 1px 9px -2px #E79F49 !important; */
}
.modal-dialog .modal-header {
  /* background: linear-gradient(111.68deg, rgb(21, 2, 54) 0%, rgb(21, 2, 54) 100%); */
  background: #101010 0% 0% no-repeat padding-box;
  border: none !important;
  padding-bottom: 1rem !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.sec-head {
  font-size: 16px !important;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px !important;
  margin-top: 7px;
}
.modal-dialog .modal-header .close {
  color: #fff !important;
  opacity: 1 !important;
  display: inline-flex;
}
.light_theme .modal-dialog .modal-header .close {
  color: black !important;
}
.wallet-lists ul li {
  list-style-type: none !important;
  transition: 0.5s;
}
.wallet-lists ul li:hover {
  /* background-color: #13003a !important; */
  background-color: #559f2b !important;
  transition: 0.5s;
}
.wallet-lists li img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.side-head-li {
  font-size: 14px !important;
}
.wallet-lists ul {
  padding-left: 0px !important;
}

.wallet-lists li {
  grid-gap: 10px;
  gap: 10px;
  padding: 16px 24px;
  display: flex;
  align-items: baseline;
  margin: 0px 0 0;
  border-radius: 8px;
  cursor: pointer;
}
.side-head-li {
  color: #fff;
}
.wal-option {
  text-align: left;
}
.modal-header p {
  font-size: 14px !important;
}

.input-group input {
  /* background: #13003a; */
  background: #393939 0% 0% no-repeat padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  border: 1px solid #393939 !important;
  color: #000 !important;
}

.input-group-text.doll {
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;

  border-radius: 0px;
  color: #fff !important;
}

.btn-purple-new {
  color: #fff !important;
}
.font_14 {
  font-size: 14px !important;
}

.input-groups input,
.input-groups .input-group-text {
  font-size: 14px !important;
}

.bord-top-none {
  border: none !important;
}
.doll-btn {
  background-color: transparent !important;
  border: none !important;
}

.input-group input,
.light_theme .input-group input,
.light_theme .input-group input.form-control {
  min-height: 42px !important;
  font-size: 12px !important;
  /* color:#d6d6d6 !important; */
}
.input-group input::placeholder,
.input-group > .form-control::placeholder {
  font-size: 12px !important;
}
.div_brn_grid {
  display: grid !important;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}

@media only screen and (max-width: 767px) {
  .footer_theme_div {
    padding-bottom: 70px !important;
  }
  .footer {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .text-right-res-new {
    text-align: right;
  }
  .flex_cont_end_flex {
    justify-content: flex-end !important;
  }
  .nav_parnt_2 {
    display: flex;
    align-items: center;
  }
  .container.container_custom {
    max-width: 1170px !important;
  }
}

/* new css */
.grid_img_div {
  display: grid;
  grid-template-columns: 90px calc(97% - 90px);
  gap: 3%;
  align-items: center;
}
.card_style_1 {
  background-color: transparent !important;
  border: none !important;
  /* border:2px solid #90c6f8 !important; */
  border-radius: 24px !important;
  /* box-shadow: 1px -2px 17px 3px #302b2b36; */
}
table,
.whitebg {
  background: white !important;
}
.form_sec .card_style_1 {
  /* border:2px solid #90c6f8 !important; */
}
.blur {
  filter: blur(4px);
}
.proj_bg .card-body {
  background: white;
}

.btn_grp_yel a {
  background-image: linear-gradient(
    to bottom,
    #16f9b2 6%,
    #16f9b2 59%,
    #16f9b2
  );
  border: 1px solid #16f9b2 !important;
  background-color: #16f9b2 !important;
  color: #fff;
  border: none;
  border-radius: 0px !important;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 !important;
}
.btn_grp_yel a:hover {
  background-color: transparent !important;
  background: transparent !important;
  color: var(--textblue) !important;
  border-color: var(--textblue) !important;
}
.badge-green {
  padding: 4px 10px !important;
  border-radius: 50px !important;
  background-color: transparent;
  border: 2px solid #508832;
  color: #508832;
  position: relative;
}
.badge-blue {
  background-color: var(--textblue);
  border: 1px solid var(--textblue) !important;
  color: var(--textblue) !important;
  border-radius: 25px !important;
  position: relative;
  padding: 11px 17px !important;
}
.badge-blue.infobtn span {
  color: #fff !important;
}
.badge-blue {
  padding: 9px 10px !important;
}
.badge-blue.infobtn span.green_dot {
  background: #fff !important;
}
.green_dot {
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 10px;
  left: 9px;
}
.green_txt {
  padding-left: 12px;
}
.badge-yellow-fill {
  background-color: #e78638;
  border: 1px solid #e78638;
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 500 !important;
  padding: 5px 10px !important;
}
.grid_img_div img {
  max-width: 90px;
  max-height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  /* border: 2px solid #35b6ff; */
  padding: 5px;
}
.yellow_txt {
  color: #000000;
  font-weight: 700;
}
.yellow_txt_sm {
  color: #000;
}
.font_20 {
  font-size: 20px;
}
.font_16 {
  font-size: 16px;
}
.font_18 {
  font-size: 18px;
}
.yellow_bar .progress-bar {
  background-color: #e79f49;
  background-image: linear-gradient(
    -200deg,
    #e79f49b2 6%,
    #e79f49 59%,
    #e79f49
  );
  border-radius: 20px !important;
}
.light_theme .yellow_bar .progress-bar {
  background-color: #e79f49;
  /* background: linear-gradient(275deg, #6300DD 0%, rgb(116 5 255 / 77%) 100%) !important; */
  background: #4b9a24 !important;
  border-radius: 20px !important;
}

.yellow_bar {
  /* background-color: #000 !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px !important;
  height: 10px !important;
  font-size: 6px !important;
}
.white_txt_sm {
  color: #fff;
}

@media only screen and (min-width: 992px) {
  #sidebar .sidebar_ul {
    max-height: calc(100vh - 160px) !important;
  }
  .whole_sec {
    padding-top: 55px;
  }
  /* .col-lg-6.col-lg-6-custom
  {
    flex: 0 0 47.5% !important;
    max-width: 47.5% !important;

  } */
  .offset-lg-1-custom {
    margin-left: 5% !important;
  }
}

.round_img {
  max-width: 90px;
  max-height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  border: 2px solid #35b6ff;
  padding: 5px;
}
.font_35 {
  font-size: 35px !important;
}
.badge-green-big {
  padding: 7px 12px 8px !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}
.badge-green-big,
.badge-green-big .green_txt {
  text-transform: uppercase !important;
  font-size: 12px !important;
}
.badge-green-big .green_txt {
  text-transform: uppercase !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.badge-green-big .green_dot {
  top: 8px !important;
}
.badge-yellow-fill-big {
  padding: 7px 16px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}

.badge-green-fill {
  background-color: #31874a;
  border: 1px solid #31874a;
  color: #fff;
  border-radius: 25px !important;
  position: relative;
  padding: 8px 20px !important;
  text-transform: uppercase;
}
.line_he_big {
  line-height: 25px;
}

.get-started-btn-fill {
  background-color: var(--textblue) !important;
  border-radius: 40px !important;
  border: 1px solid var(--textblue) !important;
  color: #fff;
  padding: 4px 25px;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: uppercase !important;
  letter-spacing: 1px;
}
.cur_pointer .get-started-btn-fill {
  pointer-events: none;
}
.get-started-btn-fill:hover {
  /* background:transparent !important; */
  border: 1px solid var(--textblue) !important;
  /* color:var(--textblue); */
  opacity: 0.65;
  color: #fff !important;
}
.tab_div .nav-pills {
  border-bottom: 1px solid #44bdff;
}

.tab_div .nav-pills .nav-link.active,
.tab_div .nav-pills .show > .nav-link,
.tab_div .nav-pills .nav-link:hover {
  background-image: linear-gradient(
    to bottom,
    var(--themeorange) 6%,
    var(--themeorange) 59%,
    var(--themeorange)
  );
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 10px 10px 0px 0px !important;
}
.tab_div .nav-pills .nav-link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin-right: 15px;
}

.table_style_1 th,
.table_style_1 td {
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  align-items: center;
  vertical-align: middle !important;
}
.table_style_1 th {
  color: #fff !important;
}
.table_style_1 td:last-child {
  text-align: right;
}
.table_style_1 th {
  background-color: #212121 !important;
}
.table_style_2 th:last-child,
.table_style_2 td:last-child {
  max-width: 120px !important;
  width: 120px !important;
}
.table_style_2 th:nth-child(3),
.table_style_2 td:nth-child(3) {
  min-width: 200px !important;
  max-width: 200px !important;
}
.searc_style_1 {
  background-color: rgb(242, 243, 249) !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  max-width: 235px;
  padding: 0px 16px !important;
  margin-left: auto;
  border: 1px solid #d7caec !important;
  border-radius: 16px !important;
  color: var(--textblue) !important;
  min-height: 40px;
  height: 40px;
  color: rgb(76, 84, 155) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.light_theme .dd_meu_header.dd_coin_landing {
  background: rgb(242, 243, 249) !important;
  color: rgb(76, 84, 155) !important;
  max-width: 168px !important;
  min-width: 168px !important;
  border: 1px solid rgb(215, 202, 236) !important;
  transform: translate(0px, 38px) !important;
  border-radius: 0px 0px 16px 16px !important;
}
.light_theme .dropdown_yelo.dropdown_purple.dropdown.show {
  border-radius: 16px 16px 0px 0px !important;
}

.dd_meu_header.dd_coin_landing .dropdown-item {
  max-width: 168px !important;
  min-width: 168px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 16px !important;
}
.dd_meu_header.dd_coin_landing .dropdown-item:hover {
  background: rgb(215, 202, 236) !important;
}
.dropdown_purple button {
  background-color: rgb(242, 243, 249) !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  max-width: 235px;
  border: 1px solid #d7caec !important;
  border-radius: 16px !important;
  min-height: 40px;
  height: 40px;
  color: rgb(76, 84, 155) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.dropdown_purple.dropdown_yelo {
  background: rgb(242, 243, 249) !important;
  border: 1px solid #d7caec !important;
  color: rgb(76, 84, 155) !important;
  height: 40px !important;
  padding: 0px 10px !important;
  border-radius: 16px !important;
  min-width: 168px !important;
}
.dropdown_purple.dropdown_yelo button {
  padding: 0px 8px !important;
  width: 100% !important;
  color: rgb(76, 84, 155) !important;
  justify-content: space-between !important;
}
/* .searc_style_1::placeholder
{
  color: rgb(76, 84, 155) !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.5;

} */
.top_heqad_sec {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 0px !important;
  }
  .top_heqad_sec .searc_style_1 {
    position: absolute;
    right: 0px;
    top: 50px;
    z-index: 2;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .top_heqad_sec .searc_style_1 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.coin_ul_desc {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;
}

.coin_ul_desc li {
  list-style-type: none;
  display: inline-block;
  margin: 0px 20px 10px;
  text-align: left;
}
.coin_desc_li_one {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: gray;
}
.coin_ul_desc li .coin_name_title {
  padding-left: 0px;
  font-weight: 600;
  font-size: 20px;
}

@media (min-width: 576px) {
  .modal-dialog.modal-dialog-lg {
    max-width: 660px;
    margin: 1.75rem auto;
  }
}

.card_toek {
  background-color: #1b1b1b !important;
  border-radius: 10px !important;
  border: none !important;
}

.coin_name_title {
  color: var(--textblue) !important;
  font-weight: 700;
  font-size: 14px !important;
}

.input-groups .input-group {
  /* border: 1px solid #393939; */
  border-radius: 6px !important;
  /* background-color: #393939; */
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  color: #d6d6d6;
  font-size: 14px !important;
}

.input-groups .input-group input.form-control::placeholder {
  color: #d6d6d6 !important;
  font-size: 12px !important;
}
.input-groups .input-group .input-group-text,
.input-groups .input-group .input-group-text.doll {
  color: rgb(76, 84, 155) !important;
  font-size: 16px !important;
}
.login_text .input-groups .input-group .input-group-text {
  color: rgb(101 174 47) !important;
}
.lightblue .yellow_txt {
  color: var(--textblue) !important;
}
.input-groups .input-group input::placeholder {
  color: #000 !important;
}

.get-started-btn-fill.text-capitalize {
  text-transform: capitalize !important;
}

.btn_row {
  justify-content: space-between;
  display: flex;
}

.input-group input:focus {
  background-color: #0000 !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.modal-open .modal {
  padding-right: 0px !important;
}

.icon_black {
  color: #fff;
  cursor: pointer;
}

.icon_black.active {
  color: var(--textblue);
}

.dropdown_yelo {
  background-image: linear-gradient(
    to bottom,
    var(--textblue) 6%,
    var(--textblue) 59%,
    var(--textblue)
  );
  border: 1px solid var(--textblue) !important;
  color: #fff;
  border-radius: 40px !important;
  padding: 4px 25px;
  font-size: 14px !important;
  font-weight: 700;
}

.dropdown_yelo .btn {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
  box-shadow: 0 0 0 0rem rgb(72 180 97 / 50%) !important;
  display: flex;
  align-items: center;

  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.dropdown_yelo img {
  max-width: 20px;
  margin-right: 5px;
}

.dd_meu_header {
  background-color: #262626 !important;
  border: 1px solid var(--textblue) !important;
  color: var(--textblue) !important;
  transform: translate(-15px, 50px) !important;
  border-radius: 20px !important;
  overflow: hidden;
}
.dd_meu_header a {
  color: var(--textblue) !important;
  cursor: pointer;
  /* border-radius: 20px !important; */
}

.dd_meu_header a:hover {
  background-color: #313131;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .affiliate_progrma_tilte {
    font-size: 18px !important;
  }
  .flex_row_cen .banner_subtitle {
    font-size: 18px !important;
  }
  .btn_lock_info_div .get-started-btn {
    padding: 0px 8px !important;
    font-size: 10px !important;
  }
  .btn4_div .get-started-btn {
    padding: 0px 8px !important;
    font-size: 11px !important;
  }
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .coimg_soon_bg {
    background-size: cover, 75% !important;
  }
  .detail-list .get-started-btn,
  .detail-list .get-started-btn-fill {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .whole_sec {
    padding-top: 140px !important;
  }
  .nav_parnt.d-flex {
    display: block !important;
  }
  .nav_parnt_1,
  .nav_parnt_2 {
    display: inline-flex !important;
  }

  .header_flex {
    flex-direction: column;
  }

  .nav_parnt {
    flex-direction: column;
    /* margin-top: 20px; */
  }
  .nav_parnt_2,
  .nav_parnt_1 {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

/* LOADER CSS */
.logo_overlay {
  min-height: 100vh;
  position: absolute;
  opacity: 0.5;
  width: 100%;
  top: 0px;
  /* background-color: #fff !important;
  background-image: url(../images/dark-bc.png) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}
.de.logo_overlay {
  opacity: 1;
}
.dark_theme .logo_overlay {
  background-color: #0a0a0a !important;
  overflow: hidden;
}
.dark_theme body {
  background-image: url(../images/dark-bc.png) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
}

#loader_div {
  position: absolute;
  min-height: 100vh;
  left: 50%;
}

.logo_ovelay {
  opacity: 0.5;
  min-height: 100vh;
  position: absolute;
  top: 0px;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.logo_load {
  max-width: 80px;
  position: absolute;
  top: calc(40% + 75px);
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
}
@keyframes loader-02 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin_round {
  animation-name: spin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 0.2em solid transparent;
  border-left-color: #55a229;
  border-right-color: #55a229;
  border-radius: 50%;
  animation: 1s loader-02 linear infinite;
  position: absolute;
  top: 40%;
  left: calc(50% - 75px);
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

body.overflow_hidden {
  overflow: hidden;
}
/* END LOADER CSS */
/* LIGHT THEME CSS */
.light_theme,
.light_theme #root {
  /* background-color: #fff !important; */
}
.light_theme .dd_meu_header {
  background-color: #fff !important;
}
.light_theme .dd_meu_header a:hover {
  background-color: #f4f4f4;
}
.light_theme .text-white {
  color: black !important;
  font-weight: 600;
}
.light_theme .icon_black,
.light_theme .side-head-li,
.light_theme .white_txt_sm,
.light_theme .tab_div .nav-pills .nav-link,
.light_theme .input-group input,
.light_theme .input-group input::placeholder,
.light_theme .input-group-text.doll {
  color: #313131 !important;
}
.light_theme .coin_name_title {
  color: white;
}
.light_theme .sec-head {
  color: #fff !important;
}
.wallet-lists li:hover h3 {
  color: white !important;
}
.light_theme .icon_black.active {
  color: var(--textblue) !important;
}
.light_theme .modal-dialog .modal-content {
  background: #fff;
}

.light_theme .modal-dialog .modal-header {
  background-color: #fff !important;
  color: white;
}
.light_theme .table_style_1 th {
  background-color: transparent !important;

  color: rgb(76, 84, 155) !important;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 12px;
}
.light_theme .card_toek {
  background-color: #f2f3f9 !important;
  color: gray;
  border-radius: 20px !important;
}
.light_theme .wallet-lists ul li:hover {
  background-color: transparent !important;
}
.light_theme .wallet-lists ul li:hover h3 {
  color: #519f27 !important;
}
.light_theme .wallet-lists ul li:hover .img {
  transform: scale(1.1);
  transition: 0.2s;
}
.light_theme .modal-dialog .modal-header {
  -webkit-box-align: center;
  align-items: center;
  /* background: linear-gradient(111.68deg, #efe0ff 0%, #efe0ff 100%); */
  background: #fff;
  display: flex;
}
.wallet-lists ul li {
  display: inline-block;
}
.wallet-lists ul {
  border-bottom: 1px solid #eb9f5147;
  padding: 23px 0 23px;
}
.wallet-lists ul li .img {
  margin-bottom: 11px;
}
button.themebtn {
  background: var(--textblue);
  color: white;
  border-radius: 30px;
  padding: 8px 16px;
  border: none;
  /* border: 1px solid #313982; */
}
button.themebtn:hover {
  opacity: 0.65;
}
.wallet-lists ul {
  display: flex;
  justify-content: space-around;
}
.light_theme .modal-dialog .modal-header h3 {
  font-weight: 600;
  color: #000 !important;
  line-height: 1.1;
}
button.close span {
  font-size: 35px;
  font-weight: 300;
}

.light_theme .tab_div .nav-pills {
  border-color: #ccc !important;
}
.light_theme .table_style_1 th,
.light_theme .table_style_1 td {
  border-color: #e7e3eb !important;
}

.light_theme .logo_overlay {
  background-color: #fff !important;
  background-image: linear-gradient(45deg, #fff, transparent) !important;
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
}
.light_theme .title_span {
  color: black !important;
  font-weight: 500;
}
/* END LIGHT THEME CSS */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn_lock_info_div .get-started-btn {
    padding: 0px 8px !important;
    font-size: 12px !important;
  }
  .btn4_div .get-started-btn {
    padding: 0px 8px !important;
    font-size: 12px !important;
  }
  .profimg {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    min-height: 70px !important;
  }
  .row_left_res .text-right {
    text-align: left !important;
  }
  /* .card_bg_wizard .d-flex
  {
flex-direction: column;
justify-content: center;
  }
  .card_bg_wizard
  {
    height: unset !important;
  }
  .card_bg_wizard .wizard_badge_num
  {
margin-bottom: 10px;
  } */
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .dd_content_lang {
    transform: translate(-50%, -5%) !important;
  }
  .locing_days_h {
    min-height: 42px;
  }
  .coimg_soon_bg {
    background-size: cover, 65% !important;
  }
  .whole_sec {
    padding-top: 140px !important;
  }
  .header_flex {
    flex-direction: column;
  }
  /* .nav_parnt.d-flex
  {
    display: block !important;
    margin-top: 20px;
  } */
  .nav_parnt_1,
  .nav_parnt_2 {
    display: inline-flex !important;
  }
}

@media only screen and (min-width: 992px) {
  .nav_parnt_1 {
    display: inline-flex !important;
    /* margin-right: 1rem !important; */
  }
}
#header {
  position: fixed;
  width: 100% !important;
  background: transparent;
  z-index: 3;
}
#header.header-scrolled {
  position: fixed;
  width: 100% !important;
  background: #fff;
  z-index: 3;
}

.light_theme #header {
  /* background-color: #f5eeff  !important;
  box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  background: #ffff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 3px 6px #6EB533 !important; */
  /* border-bottom: 1px solid gray; */
}

.light_theme #header.header-scrolled {
  /* background-color: #f5eeff   !important; */
  background: #f1f1f1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #6eb533;
}

/* HEADER NEW CSS */
/* mobile navbar */
.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}
.mobile-nav {
  position: fixed;
  top: 61px;
  right: 0px;
  left: 15px;
  z-index: 1000;
  overflow-y: auto;
  transition: ease-in-out 0.2s;
  /* border-radius: 10px; */
  padding: 10px 0;
  opacity: 0;
  /* display: none; */
}

.light_theme .mobile-nav {
  background: #f4f4f4;
}
.mobile-nav {
  background: #242424;
  padding-top: 30px !important;
}

.mobile-nav-toggle i.clicked {
  color: #fff;
  font-size: 20px;
}
.mobile-social li a span {
  background: #e6e6e8;
}
button#dropdown-basic-1 {
  color: #264663;
}
.mobile-nav.active {
  opacity: 1;
  display: block;
}
.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-nav a {
  display: block;
  position: relative;

  outline: none;
}
.mobile-nav-toggle {
  /* position: fixed;
  top: 15px;
  right: 15px; */
  z-index: 1000;
  border: 0;
  background: none;
  font-size: 20px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}
.icofont-close:before {
  content: "\eee4";
}
.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}
#mobileLayer.active {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: transparent;
  overflow: hidden;
  transition: ease-in-out 0.2s;
}
@media (max-width: 991px) {
  #sidebar {
    z-index: 9999 !important;
  }

  .dropdown-content {
    top: 40px !important;
  }
  .dropdown-content a {
    text-align: left;
  }
  div#header {
    padding: 0;
  }

  .whole_sec {
    padding-top: 56px !important;
    /* padding-bottom: 120px !important; */
    padding-bottom: 70px !important;
  }
  .nav_parnt.d-flex,
  .nav_parnt_1,
  .nav_parnt_2 {
    /* display: block !important; */
  }
  .nav_parnt_1 .dropdown_yelo {
    /* display: block !important; */
    margin-right: 0px !important;
    padding: 7px 0px;
    /* max-width: 200px !important; */
    /* min-width: 200px !important; */

    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mobile-nav-toggle i {
    color: var(--textblue);
    /* margin-top: 8px; */
  }
  .mobile-nav-toggle i.clicked {
    color: var(--textblue);
    /* margin-top: 8px; */
  }
  header .get-started-btn {
    /* max-width: 200px;
    min-width: 200px; */
    /* padding: 8px 8px !important; */
    /* margin-top: 20px; */
  }
  .logo_img_sm {
    max-width: 100px !important;
  }
}
#burger.clicked::before {
  content: "\2190" !important;
}

@media (max-width: 991px) {
  .exchange_hide_mobile_menu {
    display: none !important;
  }
  .row_frist_home .col-12:last-child {
    margin-bottom: 3rem !important;
  }
  .headerleft .dropdown.hoverbtn {
    display: none;
  }

  .dd_meu_header {
    min-width: 200px !important;
    left: 15px !important;
  }
  .theme-btn {
    margin-top: 20px !important;
  }
  .nav_parnt {
    text-align: center;
  }
  .left_stye {
    opacity: 1;
    left: 0;
    transition: 1s;
    min-height: 100vh;
    overflow-y: auto;
  }
}

header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.modal-backdrop,
.modal {
  z-index: 9998 !important;
}

#mobile_nav_item {
  display: none !important;
}
#mobile_nav_item.left_stye {
  display: block !important;
}
/* END HEADER CSS */

/* ADMIN CSS */
.input-groups .get-started-btn-fill {
  border-radius: 16px !important;
}

.inputs_switdch .input-group {
  justify-content: space-between;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  top: 0px;
  left: 6px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider.round {
  border-radius: 20px;
}
.slider,
.slider:before {
  position: absolute;
  transition: 0.4s;
}
.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
}
.slider.round:before {
  border-radius: 50%;
}
.slider:before {
  content: "";
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: left 200ms ease-in 0s;
  width: 26px;
  z-index: 1;
  /* height: 16px;
  width: 16px;
  left: 3px;
  bottom: 4.5px;
  background-color: var(--textblue); */
}
input:checked + .slider {
  background-image: linear-gradient(60deg, var(--textblue), var(--textblue));
}
input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #000;
}

.detail-list label {
  color: #fff;
  margin-bottom: 15px;
  margin-top: 15px;
}

.inputs_switdch label {
  margin-bottom: unset;
  margin-top: unset;
}

.detail-list .get-started-btn-fill {
  text-transform: capitalize !important;
}

.light_theme .detail-list label {
  color: #313131 !important;
}
.light_theme .slider {
  /* background-color: #dfdcdc; */
  background-color: rgb(242, 243, 249);
  border-radius: 24px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  height: 32px !important;
  width: 56px !important;
  transition: background-color 200ms ease 0s;
}
.input-groups.inputs_switdch .input-group {
  border: none !important;
  background: transparent !important;
  box-shadow: rgb(74 74 104 / 10%) 0px 0px 0px 0px inset !important;
}
.light_theme input:checked + .slider:before {
  background-color: #fff;
}

/* END ADMIN CSS */

/* COMING SOON CSS */
.coimg_soon_bg {
  /* background-image: url(../images/coming_bg.png),url(../images/coimg_img_bg.png);
  background-size: cover,40%;
  background-position: top,center;
  background-repeat: no-repeat,no-repeat; */
  min-height: 100vh;
}

.coimg_soon_center img {
  max-width: 200px;
}
.coimg_soon_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  padding-top: 50px;
  padding-bottom: 50px;
}

.coimg_soon_text {
  font-weight: 700;
  font-size: 40px;
}
.coimg_soon_text_white {
  color: #fff;
}
.coimg_soon_text_yellow {
  color: var(--textblue);
}
.light_theme .coimg_soon_text_white {
  color: #313131;
}
.coing_soon_img {
  position: relative;
  /* min-width: 80%; */
  /* min-height: 400px; */
}
.coing_soon_img {
  /* content: ""; */
  background-image: url(../images/coimg_img_bg.png);
  background-size: cover;
  background-position: top;
  /* position: absolute;
  top: -135px;
    width: 400px;
    height: 400px;
    left: -50%; */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn_lock_info_div .get-started-btn {
    padding: 0px 8px !important;
    font-size: 11px !important;
  }
  .btn4_div .get-started-btn {
    padding: 0px 6px !important;
    font-size: 11px !important;
  }
  .row_left_res .text-right {
    text-align: left !important;
  }
  .row_frist_home .col-12:first-child,
  .row_frist_home .col-12:nth-child(2) {
    margin-bottom: 4.3rem !important;
  }
  .coimg_soon_bg {
    background-size: cover, 55% !important;
  }
}

/* END COMING SOON CSS */

/* CRAETE CSS */
.img_card_outer {
  max-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 220px;
}
.img_card_outer img {
  object-fit: contain;
  max-height: 125px;
}

.card_img_craete .card-footer {
  background-color: var(--textblue) !important;
  background: linear-gradient(
    111.68deg,
    rgb(242, 236, 242) 0%,
    rgb(232, 242, 246) 100%
  );
  /* background-image: linear-gradient(to bottom,var(--textblue) 6%,var(--textblue) 59%,var(--textblue)); */
  color: #fff !important;
  border-radius: 0px 0px 18px 18px !important;
  font-weight: 700;
  font-size: 16px !important;
}

a {
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.font_25 {
  font-size: 25px !important;
}
.input_desc_sm {
  color: #000;
}
.yelow_desc_blk {
  color: #56596b !important;
  font-size: 14px !important;
  padding-left: 10px;
  font-weight: 400;
}
.input_desc_sm,
.light_theme .input_desc_sm {
  color: #56596b !important;
  font-size: 14px !important;
}
.form_sec.ongoing_sec .nav.nav-pills {
  justify-content: center;
  border: navajowhite;
  background-color: #fcfdff;
  /* border-bottom: 2px solid #cbced7; */
}
.swithch_inline .inputs_switdch {
  max-width: 62px !important;
}
.toggle_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.datepicker_input input {
  width: 100% !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  /* width: calc(100% + 60px) !important;
  width: calc(100% - 116px) !important; */

  outline: #f3bd32 auto 0px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #f3bd32 !important;
  color: #000000 !important;
}
.date_inoput_grps {
  display: flex;
}
/* .date_inoput_grps .input-group,.date_inoput_grps input,
.date_inoput_grps .react-datepicker__input-container
{
  width: calc(100% - 60px) !important;
} */
.date_inoput_grps .input-group {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.date_inoput_grps .input-group-append,
.date_inoput_grps .input-group-append button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.cur_pointer {
  cursor: pointer;
}

.note_desc p {
  color: #ffa500 !important;
  font-size: 12px !important;
}
.light_theme .note_desc p {
  color: black !important;
  font-weight: 500;
}

.text_are_grp textarea {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  color: #fff !important;
  resize: none !important;
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
  width: 100% !important;
  border: 1px solid #eb9f51 !important;
}
.swithch_inline .input-group {
  border: none !important;
}
.inputs_switdch {
  margin-right: 10px;
}
/* .light_theme .input_desc_sm,
.light_theme .text_are_grp textarea
{
  color:#000 !important;
} */

/* END CREATE CSS */

.logo_img_sm {
  max-width: 65px !important;
  background-image: url(../images/logo_grad.png);
  background-size: contain;
  background-position: left;
  width: 50px;
  height: 45px;
  background-repeat: no-repeat;
  display: block;
}
.web_menu {
  padding-top: 5px;
  padding-bottom: 5px;
}
.dark_theme .logo_img_sm {
  background-image: url(../images/Bitdeal/logo_curve.png);
}
body {
  /* background-image: url(../images/light-bc.png);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat; */
}
body.dark_theme {
  /* background-image: url(../images/dark-bc.png);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat; */
}
/* .....v...... */
.border-rad-15 table {
  border-radius: 24px;
}
.border-rad-15 {
  border-radius: 24px;
  border: 1px solid #d1d3d5;
}
.table-responsive.border-rad-15 td {
  padding: 10px 15px;
}
.upbtn {
  background: #e78638;
  border-color: #e78638 !important;
  color: white !important;
}
.mobile-nav a.dropbtn {
  color: #16f9b2;
}
.dropdown.headerlinkbtn.hoverbtn button {
  color: #16f9b2;
  background: transparent;
  border: none;
  height: 47px;
  line-height: 1;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.hoverbtn .dropdown-menu {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(231, 227, 235);
  border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.mobile-nav .headerlinkbtn {
  display: block !important;
  margin-right: 0px !important;
  padding: 7px 25px;
  max-width: 200px !important;
  min-width: 200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #16f9b2;
  border-radius: 28px;
  margin-top: 20px;
}
.mobile-nav .dropdown.hoverbtn:hover .dropdown-content {
  width: auto;
}
.mobile-nav .dropdown.hoverbtn:hover .dropbtn {
  background-color: #f6f7ff00;
}
.mobile-nav button.dropbtn {
  color: white !important;
  height: 0 !important;
  line-height: 1 !important;
  padding: 4px;
}
.headerright.dropdown.headerlinkbtn.hoverbtn button {
  height: auto;
  padding: 0;
}
.headerright.dropdown.hoverbtn:hover button {
  background: transparent;
}
.headerright .dropdown-content {
  width: auto !important;
  margin-top: 5px;
}
.headerright.dropdown.headerlinkbtn.hoverbtn button {
  color: rgb(255 255 255);
}
.headerleft {
  height: 55px;
  align-items: center;
}
.hoverbtn .dropdown-menu a {
  color: rgb(76, 84, 155);
}
.dropdown.hoverbtn.active button.dropbtn {
  font-weight: 600;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04aa6d00;
  color: #16f9b2;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown.hoverbtn {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  /* margin-top: 4px; */
}

/* Links inside the dropdown */
.dropdown-content a {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  background: transparent;
  color: rgb(76, 84, 155);
  cursor: pointer;
  font-weight: 400;
  display: flex;
  font-size: 16px;
  height: 48px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  outline: 0px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f6f7ff;
  color: rgb(76, 84, 155);
}

/* Show the dropdown menu on hover */
.dropdown.hoverbtn:hover .dropdown-content {
  display: block;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(231, 227, 235);
  border-radius: 16px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 280px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown.hoverbtn:hover .dropbtn {
  background-color: #f6f7ff;
  border-radius: 18px;
}

.wallet-lists button {
  /* width: 100%; */
  /* padding: 14px; */
}
.hoverbtn:hover .dropdown-menu.dd_meu_header {
  display: block !important;
  opacity: 1 !important;
}
.dropdown.headerlinkbtn.hoverbtn button:focus + .dropdown-menu,
.dropdown.headerlinkbtn.hoverbtn button:hover + .dropdown-menu {
  display: block !important;
}
.countdown div span {
  display: inline-block;
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #f2ba30;
  padding: 0;
  position: relative;
}
.countdown {
  /* margin-left: 15px; */
  margin-right: 12px;
}
.countdown div > span {
  flex: 1;
}
.countdown div span:hover {
  color: #f2ba30;
}
.countdown div span span {
  border: none;
}
.countdown div {
  display: flex;
  gap: 4%;
  /* justify-content: center; */
  /* width: 70%; */
  /* margin: auto; */

  font-size: 14px;
  color: #8f8f8f !important;
  font-weight: 700;
}
.countbtn.countinfo .countdown div {
  color: #2f800b;
}
.countbtn.countup .countdown div {
  color: #16f9b2;
}
.countdown div span span {
  display: block;
  font-size: 15px;
  background: #f2ba30;
  margin-bottom: 0;
  border-radius: 0;
  line-height: 1.3;
}

.datepicker_input input {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
  outline: 0px !important;
}

/* .salecount .badge.upbtn {
  padding: 14px 19px !important;
  font-size: 15px;
}
.salecount span.green_dot {
  margin-top: 12px !important;
} */
/* .infobtn span {
    color: #2f800b !important;
} */
/* .infobtn .green_dot {
  background: #2f800b;
} */
/* .infobtn  {
  border-color: #2f800b;
}

.dangerbtn span {
  color: #bb3a3a !important;
} */
.green_dot {
  background: #fff;
}
/* .dangerbtn  {
border-color: #bb3a3a;
} */
.dangerbtn {
  background: transparent;
  border-color: #ad0a1a;
  color: #ad0a1a;
}

/* .upbtn span {
  color: var(--textblue) !important;
} */
/* .upbtn .green_dot {
background: var(--textblue);
} */
/* .upbtn  {
border-color: var(--textblue);
} */

.countbtn span.green_txt {
  display: inline-block;
}
.countbtn span.green_dot {
  display: inline-block;
  margin-top: 2px;
}

.countbtn .badge {
  font-weight: 400;
}

button[disabled] {
  opacity: 0.4;
}
button[disabled]:hover {
  background: transparent;
  opacity: 0.4;
  color: #fff;
}

.browsebtn {
  position: relative;
  width: 100px;
}
.browsebtn input {
  width: 100px;
  cursor: pointer;
  height: 48px !important;
}
.browsebtn label {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  background: #e78638;
  width: 100px;
  border-radius: 16px;
  text-align: center;
  line-height: 3;
  color: #fff !important;
  pointer-events: none;
  height: 48px !important;
  font-weight: 700;
  border: 1px solid #ed8638;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
}
.light_theme .detail-list .browsebtn label {
  color: #fff !important;
}

.ribbon {
  content: "";
  height: 22px;
  background-image: linear-gradient(
    to bottom,
    var(--textblue) 6%,
    var(--textblue) 59%,
    var(--textblue)
  );
  position: absolute;
  right: -35px;
  width: 132px;
  top: 13px;
  z-index: 2;
  display: block;
  left: 0;
  margin-left: auto;
  transform: rotate(38deg);
  text-align: center;
  color: #fff;
}
.ribbox {
  overflow: hidden;
  position: relative;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
}

.modalbtn .inputs input {
  border: 1px solid #d7caec;
  border-radius: 16px !important;
  background-color: rgb(242, 243, 249);
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  color: rgb(76, 84, 155) !important;
  font-size: 16px !important;
}
.modalbtn .inputs input::placeholder,
.light_theme .input-group input.form-control::placeholder {
  /* color: rgb(76, 84, 155) !important; */
  font-size: 12px !important;
}
.modalbtn .badge-yellow-fill-big {
  /* padding: 5px 15px 8px 15px !important; */
}
a {
  outline: none !important;
}
.get-started-btn.get-started-btn-fill,
.get-started-btn-fill {
  background-image: linear-gradient(
    to bottom,
    var(--textblue) 6%,
    var(--textblue) 59%,
    var(--textblue)
  );
  border: 1px solid var(--textblue) !important;
  color: #fff !important;
  cursor: pointer;
}
.get-started-btn.get-started-btn-fill:hover,
.get-started-btn-fill:hover {
  color: white !important;
  /* background: transparent !important; */
  /* color:var(--textblue) !important; */
}
.purple_head.yellow_txt_sm {
  color: #10103d !important;
  background: -webkit-linear-gradient(#10103d, #10103d, #10103d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
}
.desc_grey_txt {
  color: #fff !important;
  font-size: 13px !important;
}

.desc_green_txt {
  color: #9cd947 !important;
  font-size: 13px !important;
}

.desc_green_txt_link_a:hover {
  color: #fff !important;
}
.light_theme .desc_grey_txt {
  color: black !important;
  font-weight: 500;
}
.text_blue_perc {
  /* color: #184370 !important; */
  font-weight: 600 !important;
}
.text_black_perc {
  color: #000 !important;
  font-weight: 600 !important;
}

.dark_theme .yellow_txt,
.dark_theme .yellow_txt_sm,
.dark_theme .text_black_perc,
.dark_theme .text_blue_perc {
  color: #fff !important;
}
.dark_theme .purple_head.yellow_txt_sm {
  color: #10103d !important;
  background: -webkit-linear-gradient(#0ba3ff, #2ab1ff, #56c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.tab_div .nav-pills .nav-link.active,
.tab_div .nav-pills .show > .nav-link,
.tab_div .nav-pills .nav-link:hover {
  color: #fff !important;
}

.dark_theme .table_style_1 th,
.dark_theme .table_style_1 td {
  color: #fff !important;
}
.cur_pointer {
  cursor: pointer !important;
}
.word_brk_addrs {
  word-break: break-all;
}
.a_blue_txt {
  color: var(--textblue) !important;
}
.a_blue_txt:hover {
  color: #000 !important;
}
.dark_theme .a_blue_txt:hover {
  color: #fff !important;
}

.light_theme .badge-yellow-fill-big.text-white,
.dark_theme .badge-yellow-fill-big.text-white {
  color: var(--textblue) !important;
  font-size: 14px !important;
}
.light_theme .badge-yellow-fill-big.text-white:hover,
.dark_theme .badge-yellow-fill-big.text-white:hover {
  color: #fff !important;
  font-size: 14px !important;
}

.light_theme .badge-yellow-fill-big.text-white.btn-danger,
.dark_theme .badge-yellow-fill-big.text-white.btn-danger {
  color: #fff !important;
}
.light_theme .badge-yellow-fill-big.text-white.btn-danger:hover,
.dark_theme .badge-yellow-fill-big.text-white.btn-danger:hover {
  background-color: transparent !important;
  color: #dc3545 !important;
}
.row_add_remove {
  align-items: center !important;
}
.badge-green.upbtn {
  border: 1px solid #666ba1 !important;
  color: #666ba1 !important;
  background: transparent !important;
}
.badge-green.upbtn .green_dot {
  background: #fff !important;
}
.badge-green.upbtn span {
  color: #666ba1 !important;
}
span.badge.infobtn.badge-blue {
  background: #31874a;
}

/* .............v......media */
@media (max-width: 767px) {
  .grid_img_div img,
  .profimg {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .grid_img_div {
    grid-template-columns: 65px calc(97% - 70px);
  }
}
@media (min-width: 767px) {
  tr {
    font-size: 18px;
  }
  .formhead {
    font-size: 40px;
  }
  .lightblue .card-footer p {
    font-size: 20px !important;
  }
}

/* ...............dark-theme............. */
/* .dark_theme .dd_meu_header a,.dark_theme .ongoing_sec a.nav-link.active,.dark_theme .ongoing_sec a.nav-link p,
.dark_theme .text-blue.formhead,.dark_theme .banner_title  {
  color: #fff !important;
}
.dark_theme .lightblue {
  background: #000000 !important;
}
.dark_theme #header{
  border-bottom: 1px solid #505050;
}
.dark_theme .hr_yellow {
  border-top: 1px solid #505050 !important;
} */

.dropbtn.active,
.dropbtn.active {
  color: #16f9b2 !important;
  font-weight: 600;
}

/* NEW CSS */
.dropdown_yelo.btn_globe {
  background: transparent !important;
  border: none !important;
  fill: rgb(76, 84, 155);
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.btn_settings.btn_globe {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.dd_content_lang {
  right: 50% !important;
  left: 50%;
  transform: translate(-50%, 65%);
  border-radius: 4px !important;
  border: none !important;
  box-shadow: rgb(25 19 38 / 10%) 0px 2px 12px -8px,
    rgb(25 19 38 / 5%) 0px 1px 1px !important;
  padding: 16px !important;
  max-height: 400px;
  width: max-content !important;
  min-width: 128px !important;
}
.dd_content_lang a {
  min-height: 32px;
  height: auto;
  color: rgb(76, 84, 155);
  padding: 0px 8px;
  border-radius: 8px;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  background-color: transparent;
  box-shadow: none;
  text-align: center;
  width: 100% !important;
}

.dd_content_lang a:hover {
  background: transparent !important;
  color: rgb(76, 84, 155) !important;
  opacity: 0.65 !important;
}
.settings_modal .modal-dialog {
  max-width: 420px;
  /* max-width: 420px;
  width: auto;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto; */
}
.dropdown_yelo.dropdown_yelo_mdl {
  background: transparent !important;
  border: none !important;
}
.dropdown_yelo.dropdown_yelo_mdl li {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 14px;
  background-color: rgb(246, 247, 255);
  box-shadow: none;
  color: rgb(76, 84, 155);
  margin-top: 4px;
  margin-right: 4px;
}
.dropdown_yelo.dropdown_yelo_mdl li:hover,
.dropdown_yelo.dropdown_yelo_mdl li.active:hover {
  opacity: 0.65 !important;
  color: rgb(76, 84, 155) !important;
}

.dropdown_yelo.dropdown_yelo_mdl li.active {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 32px;
  padding: 0px 14px;
  background-color: rgb(76, 84, 155);
  color: white !important;
  margin-top: 4px;
  margin-right: 4px;
}

.dropdown_yelo.dropdown_yelo_mdl li.active a {
  color: #fff !important;
}
.dropdown_yelo.dropdown_yelo_mdl li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  color: rgb(76, 84, 155);
}
.modal_content_title {
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.modal_content_title_sm {
  color: rgb(76, 84, 155);
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 12px;
}

.modal-body {
  padding: 24px 17px 20px 17px !important;
}
.btn_settings.btn.btn_globe {
  fill: #16f9b2;
}
.modal-backdrop {
  /* background-color: #0a0012 !important; */
  background-color: rgb(59, 59, 59) !important;
  transition: opacity 0.4s ease 0s;
  opacity: 0.6;
}
.modal-backdrop.show {
  opacity: 0.5 !important;
}
.modal-content {
  border: none !important;
}
.modal-header .close:hover {
  opacity: 0.65 !important;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 342px !important;
    max-width: 342px !important;
  }
}

.modal-header .sec-head {
  margin-top: 0px !important;
}
/* .light_theme .side-head-li
{
  color: #848484 !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: 14px !important;
} */
.hoverbtn:hover {
  background: rgb(246, 247, 255);
  border-radius: 16px;
  opacity: 1 !important;
}
.dropdown.hoverbtn:hover .dropbtn {
  opacity: 1 !important;
}
.dropdown.hoverbtn:hover .dropbtn:hover {
  color: #16f9b2 !important;
}
.hoverbtn .dropbtn {
  padding: 11.5px 11px !important;
}
.btn_globe.hoverbtn .dropbtn {
  padding: 0px !important;
}
.dropdown_yelo.btn_globe .dropbtn:hover {
  opacity: 0.65 !important;
}
.light_theme #header {
  height: 56px !important;
}
.container-fluid#mobileshow {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.ml-15-px {
  margin-left: 15px !important;
}
/* .dropdown.hoverbtn
{
  height: 52px;
}
.dropdown.headerlinkbtn.hoverbtn button
{
  margin-top: 2px;
} */

@media only screen and (max-width: 991px) {
  .web_mobile_menu {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 991px) {
  .logo_img_sm {
    max-width: 50px !important;
  }
}
#header1 {
  position: fixed;
  top: 0px;
  left: 0px;
  transition: top 0.2s ease 0s;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 56px;
  /* background-color: #140026; */
  background-color: #0a0a0a;
  /* border-bottom: 1px solid rgb(231, 227, 235); */
  z-index: 20;
  transform: translate3d(0px, 0px, 0px);
}

@media only screen and (max-width: 400px) {
  #mobileshow1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .dropdown.hoverbtn:hover .dropdown-content {
    left: 0px !important;
    right: unset !important;
    width: 210px !important;
  }
  header .get-started-btn {
    padding: 2px !important;
    font-size: 13px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  header .get-started-btn.btn_value_header_addr {
    padding: 5px 10px !important;
    width: unset !important;
    height: 35px !important;
    border-radius: 20px !important;
  }
  .btn_globe,
  .logo_mob_mar {
    margin-right: 0.2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .nav_green {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    justify-content: flex-start !important;
    max-width: 100% !important;
    min-height: 60px;
  }
  .nav_green .nav-link {
    white-space: nowrap !important;
  }
  .dd_content_lang {
    transform: translate(-50%, 200%) !important;
  }
  .dropdown.hoverbtn:hover .dropdown-content {
    bottom: 60px !important;
    top: unset !important;
  }
}
.wallet_desc_blue {
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: center;
}
.themebtn {
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  background-color: rgb(76, 84, 155);
  color: rgb(255, 255, 255);
  width: 100%;
}
.mod_pad_space {
  padding: 24px;
}
.banner_subtitle {
  font-weight: 600;
  line-height: 1.1;
  font-size: 24px;
  color: #fff;
}
.label_input {
  font-size: 12px;
  color: #727272;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 5px;
}
.get-started-btn.btn_rect {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex !important;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 40px;
  padding: 0px 24px;
  background-color: transparent;
  border: 2px solid #16f9b2;
  box-shadow: none;
  color: rgb(76, 84, 155);
}
.mt_18_px {
  margin-top: 18px !important;
}
.get-started-btn.btn_rect:hover {
  opacity: 0.65 !important;
  background-color: transparent !important;
  background: transparent !important;

  border: 2px solid #16f9b2;
  color: rgb(76, 84, 155);
}
.col_btn_landing {
  justify-content: flex-end;
  display: flex;
}
.btn_grp_yel {
  justify-content: space-between;
}
.btn_rect_load {
  max-width: 100% !important;
  width: unset !important;
}
.light_theme .dd_meu_header.dd_coin_landing {
  left: 0px !important;
}
@media only screen and (max-width: 575px) {
  header .get-started-btn {
    padding: 2px !important;
    font-size: 13px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  .wallet-lists-new-m .liquid_raised {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .wallet-lists-new-m .wal-option .side-head-li {
    font-size: 12px !important;
  }
  .dropdown_normal button {
    min-width: 110px !important;
  }
  .row_left_res .text-right {
    text-align: left !important;
  }
  .currency_badge {
    padding: 2px 8px !important;
  }
  .card_footer_form .d-flex {
    display: block !important;
  }
  .card_footer_form .d-flex .pl-3 {
    padding-left: 0px !important;
  }
  .card_footer_form .d-flex .fa {
    margin-bottom: 10px !important;
  }
  .white_txt_sm.d-flex {
    flex-direction: column !important;
  }
  .white_txt_sm.d-flex.text_row_oneline {
    flex-direction: row !important;
  }
  .row_frist_home .col-12 {
    margin-bottom: 4.3rem !important;
  }
  .banner_title {
    font-size: 40px !important;
  }
  .light_theme .dd_meu_header.dd_coin_landing {
    min-width: 100% !important;
    left: 0px !important;
  }
  .mt_18_px {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .btn4_div .get-started-btn {
    padding: 0px 11px !important;
    font-size: 13px !important;
  }
  .row_left_res.row_left_res_new {
    display: block !important;
  }
  .row_left_res.row_left_res_new .col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .row_left_res.row_left_res_new .col-12 .text-right-res-new {
    text-align: left !important;
    margin-top: 12px !important;
  }
  .webr_res_div.d-flex {
    display: block !important;
  }
  .img_ind_circle {
    max-width: 30px !important;
    max-height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
  }
  .profimg {
    max-width: 50px !important;
    max-height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
  }
  .webr_res_div .countdown,
  .webr_res_div .countdown_grey {
    text-align: center;
  }

  .webr_res_div .countdown div {
    justify-content: center !important;
  }
  .webr_res_div .pb_card_buy {
    text-align: center;
    padding-bottom: 20px;
  }
  .grid_img_div {
    grid-template-columns: 60px calc(97% - 60px) !important;
  }
  .profimg {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
  }
  .grid_img_div img {
    max-width: 40px !important;
    max-height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }
}
.text-black-head {
  color: #000 !important;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize !important;
}
.text-uppercase.text-black-head {
  text-transform: capitalize !important;
}
.table_heade_title {
  font-size: 24px;
  color: rgb(76, 84, 155);
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.1;
}
.border-rad-15 .table_style_1 tr:first-child td {
  border-top: none !important;
}
.get-started-btn-fill.orangebtn {
  background: #e78638 !important;
  border-color: #e78638 !important;
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 16px !important;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  color: white;
  text-transform: capitalize !important;
}
.get-started-btn-fill.orangebtn:hover {
  color: #fff !important;
  border-color: #e78638 !important;
}
.lightblue .card-footer p.token_name_title {
  font-size: 24px !important;
  color: rgb(76, 84, 155) !important;
}
.lightblue .card-footer p.token_desc_title {
  color: rgb(76, 84, 155);
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5;
}
.card_img_craete .card-footer {
  border: none !important;
}
.font_label_from_blue.yellow_txt {
  font-size: 12px !important;
  text-transform: uppercase;
  color: rgb(76, 84, 155) !important;
  font-weight: 600 !important;
}
.input_desc_sm,
.light_theme .input_desc_sm {
  color: #d6d6d6 !important;
  font-size: 13px !important;
}
.light_theme .text_are_grp textarea {
  /* font-size: 16px !important; */
  /* color: #16f9b2 !important; */
  /* padding: 0.375rem 0.75rem; */
}

.countdown_grey {
  color: #8f8f8f !important;
  font-size: 13px !important;
}
.countup.countbtn .badge {
  background: transparent !important;
  border-color: #16f9b2 !important;
  color: #16f9b2 !important;
}

.modalbtn .inputs input::placeholder,
.light_theme .input-group input.form-control::placeholder {
  /* color: rgb(76, 84, 155) !important; */
  font-size: 12px !important;
}
.light_theme .badge-yellow-fill-big.text-white,
.light_theme .badge-yellow-fill-big.text-white.btn-danger {
  background: #e78638 !important;
  border: 1px solid #e78638 !important;
  color: #fff !important;
  border-radius: 10px !important;
}
.light_theme .badge-yellow-fill-big.text-white:hover,
.light_theme .badge-yellow-fill-big.text-white.btn-danger:hover {
  background: #e78638 !important;
  background-color: #e78638 !important;

  border: 1px solid #e78638 !important;
  color: #fff !important;
  border-radius: 10px !important;

  opacity: 0.65 !important;
}

.form-control {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.searc_style_1 {
  font-size: 16px !important;
}
@media only screen and (min-width: 768px) {
  .dark_theme .logo_img_sm,
  .light_theme .logo_img_sm {
    background-image: url(../images/Bitdeal/lightlogo.png) !important;
    max-width: 160px !important;
    width: 160px !important;
    height: 41px !important;
  }
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 65px !important;
  }
}
/* 
.headerlinkbtn.hoverbtn .dropbtn
{
  height: 52px !important;
} */
span.badge.infobtn.badge-blue.btn_timer {
  background: transparent !important;
  font-size: 12px !important;
  border: none !important;
}
span.badge.infobtn.badge-blue.btn_timer span {
  font-size: 12px !important;
}
span.badge.infobtn.badge-blue.btn_timer .green_dot {
  background: #16f9b2 !important;
}
span.badge.infobtn.badge-blue.btn_timer .green_txt {
  color: #16f9b2 !important;
}
.countdown {
  font-size: 12px !important;
}
/* END NEW CSS */

/* NIVITHA */
.footer_menu {
  display: none !important;
}
.lockdetail_table td:nth-child(4) {
  min-width: 150px;
}
.whole_sec.pb-5 {
  padding-bottom: 0px !important;
}
.badge_cur_modal a {
  color: #d6d6d6 !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.badge_cur_modal a:hover {
  color: #000 !important;
  font-weight: 400 !important;
}
.header_odal_head {
  display: flex;
  align-items: center !important;
}
.modal-content .card_bg {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.btn_social_new_link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.topBar {
  padding-left: 10px !important;
}
.countdown_new .cout_man_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.cout_man_div div {
  background-color: #000 !important;
  background: #62ac2e !important;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  padding: 6px;
}
.link_grn_new {
  color: #18e5bc !important;
}
.link_grn_new:hover {
  color: #446eea !important;
}
.trans_cal_btn {
  /* background: #140038 !important;
  border: 1px solid #eb9f51 !important; */
  background: #52a027 !important;
  border: 1px solid #56a228 !important;
  min-height: 42px;
  border: none;
  color: #fff;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.copy_hover {
  cursor: pointer;
}
.copy_hover:hover {
  color: #16f9b2 !important;
}
.card_bg_wizard {
  background-color: transparent !important;
  height: 100%;
  cursor: pointer;
}
.card_bg_wizard.active,
.card_bg_wizard:hover {
  border-radius: 10px !important;
  /* background-color: #12003C  !important;
  box-shadow: 0px 1px 9px -1px #E79F49  !important; */
  background: #101010 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #4b9a24;
}
.currency_badge {
  background-color: #373737;
  border-radius: 15px !important;
  color: #d6d6d6 !important;
  padding: 4px 14px;
  font-size: 12px !important;
  cursor: pointer;
}
.currency_badge:hover,
.currency_badge.active {
  /* background-color: #E79F49; */
  background: #4b9a24 0% 0% no-repeat padding-box;
  color: #fff !important;
}
.custom-control-label {
  color: #d6d6d6 !important;
  font-size: 12px !important;
}
.custm_sel {
  border: 1px solid #393939 !important;
  border-radius: 6px !important;
  background-color: #393939 !important;
  color: #d6d6d6 !important;
  font-size: 12px !important;
  min-height: 44px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #4b9a24 !important;
  background-color: #4b9a24 !important;
}
.light_theme .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #55a228 !important;
  background-color: #55a228 !important;
}

.custom-control-label span {
  color: #ffa500 !important;
}
.card_footer_form {
  background-color: #000000;
  border: 1px solid #4b9a24 !important;
  border-radius: 10px;
  color: #fff;
  font-size: 11px;
  padding: 10px 10px;
  text-align: center;
}
.text-danger-war {
  color: #f00;
  font-size: 30px !important;
}
.wizard_badge_num {
  /* background-color: #E79F49; */
  background: #9cd947 0% 0% no-repeat padding-box;
  width: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
  min-height: 35px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}
.create_wiz_desc h5 {
  color: #d6d6d6;
  font-size: 14px !important;
}
.create_wiz_desc p {
  color: #d6d6d6;
  font-size: 12px !important;
}
.btn_social {
  background-color: #0c0c0c !important;
  background: #0c0c0c !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #56a229;
  border: none !important;
}
.light_theme .btn_social {
  color: #52a027;
}
.btn_social_new {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none !important;
}
.light_theme .btn_social_new {
  /* background: linear-gradient(275deg, #6300DD 0%, rgba(49, 6, 103, 0.72) 100%); */
  background: transparent linear-gradient(289deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
}
.sale_deta_name {
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
}
.img_ind_circle {
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  border-radius: 50%;
}
.btn_social:hover {
  background-color: #eb9f51 !important;
  background: #57a329 !important;
  color: #fff;
}
.btn_social_new:hover {
  background-color: #16f9b2 !important;
  background: #e79f49 !important;
  color: #0c0c0c;
}
.font_12 {
  font-size: 12px !important;
}
.hr_green {
  /* background-color: #eb9f51; */
  background-color: #57a429ba;
}
.light_theme .hr_green {
  background-color: #13002866;
}
.apexcharts-legend-text {
  color: #fff !important;
}

.badge-green-rect {
  background-color: #e79f49;
  color: #fff;
  border-radius: 5px !important;
  position: relative;
  font-weight: 500 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 50px !important;
}
.badge-kyc-rect {
  background-color: #1d94e1;
  color: #fff;
  border-radius: 5px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 50px !important;
}
.badge_live {
  background-color: #5da82c;
  color: #053627;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
}
.badge_upcoming {
  background-color: #ff4409;
  background-image: linear-gradient(45deg, #5957ef, #1d97e0, #18e5bc);
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
}
.badge_finish {
  background-color: #7b7a7a;
  color: #fff;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
}
.badge_cancel {
  background-color: #9e9e9e;
  color: #000;
  border-radius: 50px !important;
  position: relative;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-width: 80px !important;
}
.flex_side_right {
  display: flex;
  justify-content: end;
}
#sidebar {
  min-width: 250px;
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  overflow-y: auto;
  /* background-color: #130027; */
  background: #000 0% 0% no-repeat padding-box;
  position: sticky;
  top: 57px;
  z-index: 1;
}
#sidebar.side_shrink {
  min-width: 20px;
}
#sidebar.side_shrink .lis_paren_side span,
#sidebar.side_shrink .lis_paren_side_accordion span,
#sidebar.side_shrink .btn_white_outline_round,
#sidebar.side_shrink .mode_taxt,
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
  display: none;
  min-width: 20px !important;
}
#sidebar.side_shrink .inner_card_body_acc {
  position: fixed;
  left: 50px !important;
  background-color: #0a0a0a !important;
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin-top: -30px;
  /* top:0px !important; */
}

#sidebar.side_shrink .inner_card_body_acc .sidebar_inner_ul {
  padding-right: 1.5rem !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
  max-width: 30px !important;
  min-width: 30px !important;
}

#sidebar.side_shrink .lis_paren_side:hover {
  min-width: 30px;
  max-width: 30px;
  padding-left: 7px;
}
#sidebar.side_shrink .footer_theme_div {
  left: 3px !important;
}
#sidebar.side_shrink .lis_paren_side_accordion:hover button {
  padding-right: 0px !important;
}

#sidebar.side_shrink .shrink_side_btn {
  left: 60px !important;
}

#sidebar.side_shrink .sidebar_ul {
  margin-left: 5px !important;
}
.shrink_side_btn {
  position: fixed;
  left: 235px;
  top: 50vh;
}
.right_side_sec {
  width: calc(100% - 250px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.right_side_spacing {
  padding-left: 20px;
  padding-right: 20px;
}
.sidebar_shr .right_side_sec {
  width: calc(100% - 50px);
  /* width: 100%; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.sidebar_ul {
  padding-top: 30px !important;
  padding-left: 0px !important;
  margin-left: 10px;
  margin-right: 10px;
}
.sidebar_ul li {
  list-style-type: none !important;
  padding: 5px 10px;
  margin-bottom: 20px;
  /* max-width: fit-content; */
}
.li_bot_less {
  margin-bottom: 10px !important;
}
.sidebar_ul li img {
  max-width: 15px;
}
.sidebar_ul span {
  color: #d6d6d6;
  padding-left: 10px;
  font-size: 13px;
}
.sidebar_inner_ul a {
  font-size: 13px;
  color: #d6d6d6 !important;
}
#sidebar .accordion .card {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
}

#sidebar .accordion .card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#sidebar .accordion .card button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #fff !important;
  text-decoration: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.sidebar_inner_ul a {
  color: #d6d6d6 !important;
}
.sidebar_inner_ul a:hover,
.sidebar_inner_ul a.active {
  color: #9cd947 !important;
}
.btn_white_outline_round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
}
.btn_white_outline_round .fa {
  margin-top: 2.5px;
}
.lis_paren_side {
  max-width: 180px;
}
.dashimg {
  /* border: 1px solid #9CD947; */
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: -12%;
  /* left: 35%; */
  transform: translate(-50%, -50%);
  max-width: 30%;
  background: #101010 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #4B9A24; */
  box-shadow: 0px 0px 6px #4b9a24;
}
.light_theme .dashimg {
  background-color: #fff;
}
.parent_1_grad.active .lis_paren_side {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
}
.light_theme .parent_1_grad.active .lis_paren_side {
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
}
.lis_paren_side:hover {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.2) 100%); */
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  border-radius: 0px 10px 10px 0px;
}
.light_theme .lis_paren_side:hover {
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  border-radius: 0px 10px 10px 0px;
}
.lis_paren_side_accordion:hover .icon_text_div {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
.light_theme .lis_paren_side_accordion:hover .icon_text_div {
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
.lis_paren_side_accordion .icon_text_div {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.theme_icon,
.text_splash {
  color: #d6d6d6 !important;
  font-size: 13px !important;
}
.theme_icon {
  cursor: pointer;
}
.theme_icon:hover {
  color: #eb9f51 !important;
}
.footer_theme_div {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.hr_yellow {
  /* border-color: #eb9f51 !important; */
  border-color: #4b9a2480 !important;
}
.light_theme .hr_yellow {
  border-color: #f0dffd !important;
}
.nav_green {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav_green .nav-link.active,
.nav_green .nav-link:hover {
  background: #4b9a24 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}
.nav_green .nav-link {
  /* background-color: #101010 !important; */
  color: #9cd947 !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px !important;
  /* box-shadow: 0px 1px 9px -1px #eb9f51 !important; */
  font-size: 13px !important;

  background: #101010 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #4b9a24;
}
.dropdown_normal button {
  /* background-color: #13003a   !important; */
  background: #393939 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  border-color: #393939 !important;
  color: #ffff !important;
  font-size: 13px !important;
  box-shadow: 0 0 0 0rem rgb(72 180 97 / 50%) !important;
  min-width: 120px !important;
  width: 100%;
  padding: 10px 13px;
  text-align: left !important;
}
.dropdown_normal_menu {
  background-color: black !important;
  border-radius: 8px !important;
  min-width: 120px !important;
  text-align: left !important;
  color: #fff !important;
  width: 100%;
}
.dropdown_normal_menu a {
  color: #fff !important;
  font-size: 13px !important;
}
.dropdown_normal_menu .dropdown-item:hover {
  background-color: #4b9a24 !important;
}
.searc_style_2 {
  /* background-color: #13003a  !important; */
  background: #393939 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  color: #fff !important;
  border-color: #393939 !important;
  font-size: 13px !important;
  padding: 10px 12px !important;
  height: unset !important;
}
.searc_style_2::placeholder {
  color: #727272 !important;
  font-size: 12px !important;
}
/* THEME CSS */
body.dark_theme {
  background-color: #000000 !important;
}
body.dark_theme #header.header-scrolled {
  /* background-color: #130027 !important; */
  background: #0a0a0a 0% 0% no-repeat padding-box !important;
}
body.dark_theme #header {
  /* background-color: #130027 !important; */
  background: #0a0a0a 0% 0% no-repeat padding-box;
}
body.dark_theme .right_side_sec {
  /* background-color: #090013 !important; */
  background: #0a0a0a 0% 0% no-repeat padding-box !important;
}
.dark_theme .card_style_1 .card-body {
  /* background-color: #12003C !important; */
  background: #101010 0% 0% no-repeat padding-box !important;
}

.dark_theme .card_style_1 {
  /* background-color: #101010 !important; */
  background: #101010 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #9cd947;
}
/* END THEME CSS */
/* END NIVITHA */

/* kr css */
.filter_dropdown {
  display: inline-flex !important;
}
.filter_dropdown .dropdown-toggle {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  color: #727272 !important;
}
.filter_dropdown .dropdown-toggle::after {
  content: none !important;
}
.table_filter_icon {
  box-shadow: 0 0 0 0 rgb(38 143 255 / 50%) !important;
}
.table_dropdown_menu {
  background-color: #373737 !important;
  max-height: 150px !important;
  overflow-y: auto !important;
}
.table_dropdown_menu a {
  color: #727272 !important;
}
.table_dropdown_menu a:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.table_dropdown_menu a:last-child:hover {
  background-color: #373737 !important;
  color: #727272 !important;
}
.fa-filter {
  font-size: 12px !important;
}
.relative_token_div {
  max-height: 25px;
  max-width: 25px;
  min-height: 25px;
  min-width: 25px;
  background: #101010;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #565656;
}
.absolute_token_div {
  margin-left: -10px;
  max-height: 25px;
  max-width: 25px;
  min-height: 25px;
  min-width: 25px;
  background: #101010;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #565656;
}
.trendss a {
  color: #a29d9d;
  font-weight: 500;
  white-space: nowrap;
}
.trendss a span {
  color: #a29d9d !important;
}
.trendss a:hover,
.trendss a:hover span {
  color: #62ca28 !important;
}

.light_theme .trendss a span {
  color: #a29d9d !important;
}
.light_theme .trendss a:hover,
.light_theme .trendss a:hover span {
  color: #62ca28 !important;
}
.token_image_sm {
  height: 15px !important;
  width: 15px !important;
}
::-webkit-scrollbar {
  height: 6px;
  width: 5px;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  background: #000;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box;

  border-radius: 6px;
}
.topBar {
  padding: 5px 0px 5px 0px;
  overflow-x: auto !important;

  /* background: linear-gradient(270deg, #2A015B 0%, #120028 100%); */
  background: #0a0a0a 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 16px 1px rgba(0, 0, 0, 0.9);
}
.topBar small {
  margin-right: 10px !important;
}
.title_span {
  font-size: 13px;
  color: #d6d6d6;
}
.topBar span {
  color: #62ca28 !important;
}
.liquid_raised {
  position: relative;
  height: 100% !important;
  padding: 35px 15px;
  /* background: #12003C; */
  background: #101010 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #4b9a24;
  border-radius: 15px;
  cursor: pointer;
}
.liquid_raised .font15 {
  /* color: #E79F49; */
  color: #fff;
}
.h3_res {
  color: #9cd947;
  font-weight: 600;
}
.liquid_raised:hover,
.card_bg:hover {
  box-shadow: 0px 1px 9px -2px #8ccc40 !important;
  /* background: #0a0a0a; */
}
.icon_align {
  position: absolute;
  padding: 15px;
  top: -40px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  min-width: 65px;
  background-color: #101010;
  border-radius: 50%;
  box-shadow: 0px 1px 9px -1px #16f9b2 !important;
  max-width: 65px;
  min-height: 65px;
  max-height: 65px;
  display: none;
}
.icon_align img {
  max-width: 100%;
}
.card_bg {
  cursor: pointer;
  /* background: #12003C !important; */
  background: #101010 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #4b9a24;
  border-radius: 15px !important;
}
.card_img_pare_div {
  background-color: #000;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.modal-content .card_bg {
  box-shadow: 0px 0px 0px 0px #16f9b2 !important;
}
.no_data_txt {
  color: #7e7e7e;
  font-size: 22px !important;
  font-weight: 600;
  text-align: center;
}
.card_img_pare_div img {
  max-width: 60px;
  max-height: 60px;
  padding: 4px;
  object-fit: contain;
}
.bottom_text {
  /* color: #E79F49; */
  color: #fff;
  font-size: 11px;
}
.sub_text {
  color: #d6d6d6;
  font-size: 12px;
}
.font15 {
  color: #d6d6d6;
  font-size: 15px;
}

.green_icons {
  color: #16f9b2;
  margin-right: 6px;
  font-size: 15px;
}
.green_icons:hover {
  color: #317fe6 !important;
}
.close_icons i {
  margin-right: 6px;
  color: #727272;
}
.table_time {
  color: #fff;
}
.progressBar {
  background-color: #fff !important;
  width: 70px;
}
.token_image {
  height: 20px;
  width: 20px;
}
.input_group {
  background: #393939 0% 0% no-repeat padding-box !important;
  color: #727272 !important;
  border-radius: 10px;
  padding: 0px 5px 0px 0px !important;
  height: 42px;
  border: none !important;
  outline: none !important;
}

.search_bar {
  min-width: 160px !important;
}
.table tr {
  /* background-color: #12003C    !important; */
  background: #393939 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}
.table tr:first-child {
  background-color: unset !important ;
}
.table {
  background-color: transparent !important;
}
.table tr {
  border-block-end: 4px solid #0000;
}
.table tr:first-child {
  border-block-end: 0px solid #101010;
}
.table th {
  border: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.table td {
  font-size: 12px;
  border: none !important;
  vertical-align: middle !important;
}
.table th:first-child {
  min-width: 160px;
}
.table th:nth-child(2) {
  min-width: 100px;
}
.table th:nth-child(3) {
  min-width: 100px;
}
.table th:nth-child(4) {
  min-width: 120px;
}
.table th:nth-child(5) {
  min-width: 130px;
}
.table th:nth-child(6) {
  min-width: 130px;
}
.table th:nth-child(7) {
  min-width: 90px;
}
.table th:nth-child(8) {
  min-width: 120px;
}
.table th:last-child {
  min-width: 140px;
}
.table td {
  border-bottom: 10px #eb9f51 !important;
}
.recepients_table td:first-child {
  min-width: 160px;
  min-height: 53px;
  align-items: center;
}
.recepients_table td:nth-child(3) {
  min-width: 100px;
  min-height: 53px;
  align-items: center;
}
.table td:nth-child(5) {
  min-width: 130px;
}
.link_text {
  color: #ffa500 !important;
  cursor: pointer;
}
.link_text:hover {
  color: #58a429 !important;
}
.active_times {
  color: #317fe6 !important;
}
.times_hover:hover {
  color: #317fe6 !important;
  cursor: pointer;
}
.input_group input {
  font-size: 12px !important;
  padding-bottom: 10px;
  color: #fff !important;
  border: none !important;
  outline: none !important;
}
.input_group.search_bar input::placeholder {
  color: grey !important;
}
.coming_soon {
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.common_search {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: none;
  outline: none;
}
.list_sec {
  text-align: end;
}
.list_sec ul {
  list-style-type: none;
  display: inline-flex;
  color: #fff !important;
}
.list_sec ul li:first-child {
  margin-right: 10px;
}
.active {
  color: #e79f49 !important;
}

.absolute_image {
  margin-left: -10px;
}
.table-responsive::-webkit-scrollbar {
  height: 6px;
  border-radius: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  background: black;
  border-radius: 6px;
}
.table-responsive::-webkit-scrollbar-thumb {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box;
  border-radius: 6px;
}
.table_text {
  font-size: 12px;
  color: #fff;
  padding: 0;
}
.sub_tabletext {
  font-size: 10px;
  color: #fff;
  padding: 0;
}
.table_det {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table_nav .nav-link.active,
.table_nav .nav-link:hover {
  /* color: #eb9f51 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #eb9f51 !important; */
  color: #529f27 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #529f27 !important;
  padding: 0 !important;
}
.light_theme .table_nav .nav-link.active,
.light_theme .table_nav .nav-link:hover {
  color: #519f26 !important;
  border-bottom: 1px solid #519f26 !important;
}
.table_nav .nav_link {
  color: #fff !important;
  padding: 0 !important;
  font-size: 12px !important;
  margin-right: 10px !important;
  border-bottom: 1px solid transparent !important;
}
.light_theme .table_nav .nav_link {
  color: black !important;
}
.pagination_btn {
  padding: 5px 10px;
  background-color: transparent;
  color: #fff !important;
  border-radius: 6px;
  border: 1px solid #fff !important;
  min-width: 30px !important;
  margin: 2px;
  min-height: 30px !important;
}
.pagination_btn:hover {
  background-color: rgb(76, 84, 155);
  background: linear-gradient(180deg, #6300dd 0%, rgba(49, 6, 103, 0.42) 100%);
  color: white;
}
.pagination_btn.active {
  /* background-color: rgb(76, 84, 155);
  background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%);
  color: white; */
  background: #54a128;
  border: 1px solid #5aa62a !important;
}
.wallet-modal .modal-dialog {
  border-radius: 10px !important;
}

.modal_header {
  background-color: #101010 !important;
}

.copy_icon {
  color: #317fe6;
  cursor: pointer;
}
.copy_icon:hover {
  color: #fff;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 95% !important;
  }
}

/* end kr css */

/* sidebar css */
.title_lockinfo {
  color: #8f8f8f !important;
  font-weight: 600 !important;
  font-size: 15px;
}
.lockdetail_table td:nth-child(4) {
  min-width: 150px;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  #sidebar.side_shrink .lis_paren_side {
    min-width: 150px !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover button {
    padding-left: 0px !important;
  }
  #sidebar {
    position: fixed !important;
    z-index: 1000 !important;
    max-height: calc(100vh - 102px) !important;
    min-height: calc(100vh - 102px) !important;

    overflow-y: auto !important;
    min-width: 0px;
    max-width: 0px;
  }
  .footer_menu_flex_side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn_bar_mobl {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
  }
  /* #sidebar .lis_paren_side span,
#sidebar .lis_paren_side_accordion span,
#sidebar .btn_white_outline_round,
#sidebar .mode_taxt,
#sidebar .lis_paren_side_accordion:hover .icon_text_div span
{
display: none;
min-width: 20px !important;
} */
  #sidebar .sidebar_ul {
    margin-left: 0px !important;
  }
  .sidebar_ul {
    margin-right: 0px !important;
    padding-left: 5px !important;
  }
  /* #sidebar
{
  max-width: 50px !important;
} */

  #sidebar .shrink_side_btn {
    left: 35px !important;
  }
  #sidebar .side_shrink {
    min-width: 250px;
    min-height: calc(100vh - 55px);
    max-height: calc(100vh - 55px);
    overflow-y: auto;
    background-color: #0a0a0a;
    position: sticky;
    top: 55px;
    z-index: 1;
  }
  #sidebar.side_shrink .shrink_side_btn {
    position: fixed;
    left: 235px !important;
    top: 50vh !important;
  }
  .side_shrink {
    min-width: 250px !important;
  }
  .right_side_sec {
    /* width: calc(100% - 50px); */
    width: calc(100% - 0px);

    margin-left: 0px;
  }

  #sidebar.side_shrink .lis_paren_side span,
  #sidebar.side_shrink .lis_paren_side_accordion span,
  #sidebar.side_shrink .mode_taxt,
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div span {
    display: inline !important;
    min-width: 20px !important;
  }
  #sidebar.side_shrink .btn_white_outline_round {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 15px !important;
    height: 15px !important;
    max-width: 15px !important;
    max-height: 15px !important;
    min-width: 15px !important;
    min-height: 15px !important;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 12px !important;
  }
  /* #sidebar .inner_card_body_acc {
    position: fixed;
    left: 50px !important;
    background-color: #0a0a0a !important;
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px;
    margin-top: -30px;
} */
  #sidebar.side_shrink .accordion .card button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: #fff !important;
    text-decoration: none !important;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  /* #sidebar .footer_theme_div {
  left: 3px !important;
} */
  #sidebar.side_shrink .footer_theme_div {
    left: 20px !important;
  }
  #sidebar .inner_card_body_acc .sidebar_inner_ul {
    padding-right: 1.5rem !important;
  }
  #sidebar.side_shrink .accordion .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  /* #sidebar .inner_card_body_acc {
  position: fixed;
  left: 50px !important;
  background-color: #0a0a0a !important;
  border-radius: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
  margin-top: -30px;
 
} */
  .light_theme #sidebar .inner_card_body_acc {
    background-color: #fff !important;
    box-shadow: 0px 1px 9px -1px #02020299 !important;
  }
  .light_theme #sidebar .collapse .inner_card_body_acc {
    box-shadow: 0px 0px 0px 0px #02020299 !important;
  }
  /* #sidebar .lis_paren_side_accordion:hover button {
  padding-right: 0px !important;
} */
  /* #sidebar .lis_paren_side_accordion:hover .icon_text_div {
  max-width: 35px !important;
  min-width: 35px !important;
} */
  #sidebar.side_shrink .inner_card_body_acc {
    position: unset !important;
    left: unset !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: unset !important;
  }
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div {
    min-width: 150px !important;
    min-height: 30px !important;
  }
}
/* end sidebar css */
@media only screen and (max-width: 575px) {
  /* .airlists ul li {
    display: unset !important;
} */
  .fklex_dircol {
    flex-direction: column-reverse;
  }
  .dropdown_normal button {
    min-width: 110px !important;
  }
  .grid_img_div img,
  .profimg {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .grid_img_div {
    grid-template-columns: 50px calc(97% - 50px) !important;
  }
  .text-right-xss {
    text-align: right !important;
  }
  .white_txt_sm .countbtn {
    text-align: center !important;
  }
  .pb_card_buy {
    padding-bottom: 20px !important;
    text-align: center !important;
  }
  .countdown_grey_cen {
    text-align: center;
  }
}
.text-right-xss {
  text-align: right !important;
  justify-content: flex-end !important;
}
.flex_cont_end_flex {
  justify-content: flex-end !important;
}
.btn_pagin_wrap {
  flex-wrap: wrap !important;
}
#header,
#header1,
#sidebar {
  box-shadow: 4px 1px 5px -2px #02020299 !important;
  /* border-right: 1px solid #4B9A24; */
  border-bottom: 1px solid #4b9a24;
}
#sidebar {
  border-right: 1px solid #4b9a24;
}
/* LIGHT THEME CSS */
.light_theme .logo_img_sm {
  background-image: url(../images/Bitdeal/logo_curve.png);
}
.light_theme #sidebar {
  /* background-color: #efe0ff !important;
  box-shadow: none !important; */
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #4b9a24 !important;
}
.light_theme .lis_paren_side_accordion {
  background-color: transparent !important;
}
.light_theme .sidebar_ul span {
  color: black;
  font-weight: 500;
}
.parent_1_grad.active .lis_paren_side span,
.parent_1_grad:hover .lis_paren_side span {
  color: #fff !important;
}
.light_theme .sidebar_ul li img {
  /* filter: brightness(0.3); */
  filter: invert(1);
}
.parent_1_grad.active .lis_paren_side img,
.parent_1_grad:hover .lis_paren_side img,
.lis_paren_side_accordion:hover .icon_text_div img {
  filter: unset;
}
.lis_paren_side_accordion:hover .icon_text_div span {
  color: #fff;
}
.light_theme .sidebar_inner_ul a,
.light_theme .table_text,
.light_theme .sub_tabletext {
  color: black !important;
}
.light_theme .sidebar_inner_ul a:hover,
.light_theme .sidebar_inner_ul a.active {
  color: #509e26 !important;
}
.light_theme .liquid_raised,
.light_theme .card_style_1,
.light_theme .pagination_btn {
  background-color: transparent !important;
  box-shadow: 0px 0px 6px #4b9a24 !important;
  /* box-shadow: 0px 0px 6px #9CD947 !important; */
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
}

.light_theme .proj_bg .card_style_1 .card-body {
  background-color: #ffff !important;
}
.light_theme .icon_align,
.light_theme .card_bg,
.light_theme .card_img_pare_div,
.light_theme .btn_social,
.light_theme #header1 {
  background-color: #fff !important;
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
  border: none !important;
}
.light_theme .btn_social:hover {
  background: transparent linear-gradient(289deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff;
}
.light_theme .sale_deta_name {
  color: #000 !important;
}
.light_theme .modal-content .card_bg {
  box-shadow: 0px 0px 0px 0px #16f9b2 !important;
  border: none !important;
}
.light_theme .liquid_raised .font15 {
  color: #130028 !important;
}
.light_theme .h3_res {
  color: #9cd947 !important;
  font-weight: 600;
}
.light_theme .sub_text,
.light_theme .theme_icon,
.light_theme .text_splash {
  color: #544762 !important;
}
.light_theme .theme_icon.active {
  color: #eb9f51 !important;
}
.light_theme .topBar span {
  color: #650bca !important;
}
.light_theme .bottom_text {
  color: black !important;
  font-weight: 500;
}
.light_theme .currency_badge {
  background: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: black !important;
  font-weight: 600;
}
.light_theme .tab_img .card_bg:hover {
  background: #fff !important ;
}

.light_theme .badge_cur_modal a {
  color: #000 !important;
}
.light_theme .wallet-lists ul {
  border-bottom: 1px solid #b3c0bc !important;
}
.light_theme .card_bg_wizard.active,
.light_theme .card_bg_wizard:hover,
.light_theme .card_footer_form,
.light_theme .cout_man_div div,
.light_theme .table_dropdown_menu {
  /* box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  box-shadow: 0px 1px 16px -1px #5aa52ae0 !important;
  background: #fff !important;
}
.light_theme .cout_man_div div {
  color: black !important;
  font-weight: 500;
}
.light_theme .yellow_bar {
  background-color: #e1e1e1 !important;
}
.light_theme .card_footer_form {
  color: black !important;
  font-weight: 500;
  border-color: #d0d0d0 !important;
}
.light_theme .create_wiz_desc h5,
.light_theme .create_wiz_desc p,
.light_theme .input_desc_sm,
.light_theme .input_desc_sm {
  color: black !important;
  font-weight: 500;
}
.light_theme .card_bg_wizard {
  border: none !important;
}
.light_theme .wizard_badge_num {
  /* background-color:#6a04da !important; */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box !important;
}
.light_theme .custom-control-label {
  color: black !important;
  font-weight: 500;
}
.light_theme .currency_badge.active,
.light_theme .currency_badge:hover {
  background-color: #5ba62b !important;
  color: #fff !important;
}
.light_theme .input-groups .input-group,
.light_theme .custm_sel,
.light_theme .searc_style_2,
.light_theme .dropdown_normal button,
.light_theme .dropdown_normal_menu,
.light_theme .input_group {
  background-color: #ffff !important;
  box-shadow: rgb(74 74 104 / 0%) 0px 2px 2px -1px inset;
  border: 1px solid black !important;
  color: black !important;
  font-weight: 500;
}
.light_theme .file_grp_input.input-group {
  border: 1px solid transparent !important;
}
.light_theme .dropdown_normal_menu .dropdown-item:hover {
  background-color: #5ea92c !important;
  color: #fff !important;
}
.light_theme .trans_cal_btn {
  background-color: #7abe38 !important;
  color: #fff;
  border: 1px solid #7abe38 !important;
}
.light_theme .input-groups .input-group input,
.light_theme .input-groups .input-group input::placeholder,
.light_theme .input-groups .input-group textarea,
.light_theme .input-groups .input-group textarea::placeholder,
.light_theme .input_group input,
.light_theme .input_group input::placeholder {
  color: black !important;
  font-weight: 500;
  font-size: 12px !important;
  background-color: #ffff !important;
}
.light_theme .input-group input {
  border: 0px solid #13002866 !important;
}
.light_theme .banner_subtitle {
  color: black !important;
}
.light_theme .nav_green .nav-link {
  background: #fff !important;
  color: black !important;
  font-weight: 600;
  /* box-shadow: none !important; */
  box-shadow: 0 3px 10px rgb(98 172 46 / 50%);
}
.light_theme .nav_green .nav-link.active,
.light_theme .nav_green .nav-link:hover {
  /* background: linear-gradient(275deg, #6300DD 0%, rgb(116 5 255 / 77%) 100%) !important; */
  background: transparent linear-gradient(289deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff !important;
}
.light_theme .adv_tab .input_group {
  border: none !important;
}
.light_theme .table tr {
  background-color: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .table tr:first-child {
  background-color: unset !important;
}
.light_theme .table tr {
  border-block-end: 3px solid #f5faff !important;
  color: black !important;
  font-weight: 500;
}
.light_theme .table tr .yellow_bar {
  background-color: #e4e0e0 !important;
}
.light_theme .green_icons,
.light_theme .link_text {
  color: #509e26 !important;
}
.light_theme .green_icons:hover {
  color: #317fe6 !important;
}
.light_theme .link_text:hover {
  color: #eb9f51 !important;
}

.light_theme .table tr .yellow_bar .progress-bar {
  background-color: #0bc592 !important;
  background: linear-gradient(
    275deg,
    #6300dd 0%,
    rgb(116 5 255 / 77%) 100%
  ) !important;
}

.light_theme .table_dropdown_menu a:hover {
  background-color: #d9dee3 !important;
  color: #848484 !important;
}

.dark_theme .input-groups .input-group input.form-control::placeholder,
.dark_theme .input-groups .input-group input {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background: #393939 0% 0% no-repeat padding-box;
}
.dark_theme .input-group input.form-control::placeholder {
  background: unset !important;
}
.custm_sel option {
  background-color: black !important;
}
.light_theme .custm_sel option {
  background-color: #fff !important;
}
/* END LIGHT THEME CSS */
.table_dropdown_menu a:last-child:hover {
  background-color: transparent !important;
}
.lis_paren_side_accordion {
  border: none !important;
}
.wallet-lists ul li:hover .img {
  transform: scale(1.1);
  transition: 0.2s;
}
.react-datepicker-popper {
  z-index: 4 !important;
}
.logo_grad_width {
  max-width: 200px !important;
}
.coming_soon.login_text {
  text-align: left !important;
}
.file_grp_input .custom-file {
  /* border: 1px solid #393939; */
  border-radius: 6px !important;
  /* background-color: #393939;
  background: #393939 !important; */
  min-height: 42px !important;
  width: 100% !important;

  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  color: #d6d6d6;
  font-size: 14px !important;
}
.file_grp_input .custom-file-label {
  /* background-color: #140038;
  background: #140038 !important; */
  background: #393939 0% 0% no-repeat padding-box !important;
  min-height: 42px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #d6d6d6 !important;
  font-size: 12px !important;

  border: 1px solid #58a42aa6 !important;
}
.file_grp_input .custom-file-label::after {
  min-height: 42px !important;
  background-color: #393939;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #d6d6d6 !important;
  font-size: 12px !important;
}
.icon_align_border {
  background-color: #000;
  min-width: 190px;
  min-height: 150px;
  max-width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 9px -2px #16f9b2 !important;
  background: #0a0a0a;
  max-height: 150px;
  /* overflow: hidden; */
  object-fit: contain;
  aspect-ratio: 1/1;
}

.light_theme .file_grp_input .custom-file-label {
  background: transparent !important;
  border-color: black !important;
  color: black !important;
  font-weight: 500;
}
.light_theme .file_grp_input .custom-file-label::after {
  background-color: #e1e1e1 !important;
  background: #5aa62b !important;
  color: #fff !important;
}
.light_theme .file_grp_input .custom-file {
  border-color: black !important;
}
.light_theme .icon_align_border {
  background-color: #ffffff !important;
  box-shadow: 0px 1px 9px -1px #02020299 !important;
}
.close_icons {
  margin-left: 10px;
}
.bold_text_proof {
  font-size: 15px !important;
}
.btn_width_auto {
  width: auto !important;
}
.table_dropdown_menu a.link_text_gren,
.table_dropdown_menu a.link_text_gren a {
  color: #e79f49 !important;
  cursor: pointer;
}
.table_dropdown_menu a.link_text_gren:hover,
.table_dropdown_menu a.link_text_gren a:hover {
  /* color: #317fe6 !important; */
  color: #56d60f !important;
  cursor: pointer;
}
.light_theme .sidebar_ul .lis_paren_side:hover span {
  color: white !important;
}
/* .light_theme .sidebar_ul li.li_bot_less:hover img
{
filter: unset;
} */
.light_theme .lis_paren_side:hover img {
  filter: unset;
}

.light_theme .liquid_raised:hover,
.light_theme .card_bg:hover {
  /* box-shadow: 0px 1px 16px -1px #6903d91f !important; */
  box-shadow: 0px 1px 16px -1px #b6d4a8 !important;
  background: #fff !important;
}

/* .launchpad_parent .lis_paren_side_accordion:hover .icon_text_div
{

} */
#launchpad_parent.active,
#privatesale_parent.active,
#lock_parent.active,
#airdrop_parent.active,
#exchangenew_parent.active {
  /* background: linear-gradient(180deg, #6300DD 0%, rgba(49, 6, 103, 0.42) 100%); */
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
.light_theme #launchpad_parent.active,
.light_theme #privatesale_parent.active,
.light_theme #lock_parent.active,
.light_theme #exchangenew_parent.active {
  /* background: linear-gradient(275deg, #6300DD 0%, rgba(49, 6, 103, 0.72) 100%); */
  background: transparent linear-gradient(101deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border-radius: 0px 10px 10px 0px;
  min-width: 150px;
  min-height: 30px;
}
@media only screen and (max-width: 991px) {
  .sidebar_shr .right_side_sec {
    width: 100% !important;
  }
  #sidebar {
    background: #292929 !important;
  }
  #footer_side {
    background-color: #0c0c0c !important;
    position: fixed;
    bottom: 0px !important;
    width: 100% !important;
    z-index: 89 !important;
    padding: 10px 20px !important;
    border-top: 2px solid #7abe38;
  }
  .light_theme #footer_side {
    background-color: #fff !important;
  }
  .light_theme #footer_side img {
    filter: brightness(0);
  }
  .light_theme #footer_side .btn_bar_mobl {
    color: #000 !important;
  }
  .footer_theme_div {
    bottom: 60px !important;
  }
  #sidebar .sidebar_ul {
    max-height: calc(100vh - 250px) !important;
  }
  .whole_sec,
  .whole_sec.pb-5 {
    padding-bottom: 70px !important;
  }
  /* .icon_theme_div
  {
    margin-left: 15px;
  } */
  .footer_theme_div {
    left: unset !important;
  }
  #launchpad_parent.active,
  #privatesale_parent.active,
  #lock_parent.active,
  #airdrop_parent.active,
  #exchangenew_parent.active {
    /* min-height: 30px;
    max-width: 30px !important;
    min-width: 30px !important; */
    display: flex;
    align-items: center;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active {
    min-width: 150px !important;
    min-height: 30px !important;
  }
  .light_theme #sidebar.side_shrink .icon_text_div#launchpad_parent.active span,
  .light_theme
    #sidebar.side_shrink
    .icon_text_div#privatesale_parent.active
    span,
  .light_theme #sidebar.side_shrink .icon_text_div#lock_parent.active span,
  .light_theme
    #sidebar.side_shrink
    .icon_text_div#exchangenew_parent.active
    span {
    color: #fff !important;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#exchangenew_parent.active img {
    filter: unset !important;
  }
}

@media only screen and (min-width: 992px) {
  /* .logo_img_sm
  {
    width: 200px !important;
  } */
  #footer_side {
    display: none;
  }
  .side_shrink .icon_theme_div {
    padding-left: 5px;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active {
    min-height: 30px;
    max-width: 30px !important;
    min-width: 30px !important;
    display: flex;
    align-items: center;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active span,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active span,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active span,
  .light_theme .sidebar_ul li .icon_text_div#exchangenew_parent.active span {
    color: #fff !important;
  }
  .light_theme .sidebar_ul li .icon_text_div#launchpad_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#privatesale_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#lock_parent.active img,
  .light_theme .sidebar_ul li .icon_text_div#exchangenew_parent.active img {
    filter: unset !important;
  }
}

.light_theme #sidebar.side_shrink .inner_card_body_acc {
  background-color: #fff !important;
  /* box-shadow: 0px 1px 9px -1px #02020299 !important; */
}

.link_wallet_new {
  color: #fff !important;
}
.wallet_address_text {
  color: #fff;
}
.wallet_address_text span {
  color: #eb9f51 !important;
  margin-left: 5px;
}
.wallet_address_text .fa {
  color: #efa058 !important;
  margin-left: 5px;
}
.wallet_address_text .fa:hover {
  color: #6c06dc !important;
}
.text_green {
  color: #efa058 !important;
}
.pool_detail_title {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  overflow-y: auto !important;
}
.table th {
  vertical-align: middle !important;
}
.table_repso_with_dropdwon {
  min-height: 250px !important;
}
.input-groups .input-group.input_grp_profile_url .input-group-text {
  background-color: #55a228 !important;
  border: 1px solid #54a228 !important;
  color: #fff !important;
  font-size: 12px !important;
}
.input-groups .input-group.input_grp_profile_url .input-group-append {
  padding: 10px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.input-groups .input-group.input_grp_profile_url .input-group-append .fa:hover {
  color: #16f9b2 !important;
}
.bnt_icon_new {
  max-width: 32px !important;
  min-width: 32px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.light_theme .wallet_address_text,
.light_theme .pool_detail_title {
  color: #000 !important;
}
.light_theme .wallet_address_text span,
.light_theme .text_green {
  color: #882af0 !important;
  font-size: 12px !important;
}
.light_theme
  .input-groups
  .input-group.input_grp_profile_url
  .input-group-text {
  background-color: #f1defb !important;
  color: #848484 !important;
  border: unset !important;
}
.btn4_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip_parent {
  position: relative;
  display: inline;
}
.tooltip_custom {
  background: #4f4a4a;
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  left: 20px;
  width: 100px;
  text-align: center;
}
.light_theme .tooltip_custom {
  background: #e1dddd;
  color: #848484 !important;
}
.btn_timer_new span {
  background-color: rgb(76, 84, 155);
  background-image: linear-gradient(101deg, #4b9a24 0%, #9cd947 100%);
  color: white;
  padding: 8px 10px;
  margin-right: 10px;
  border-radius: 10px;
  min-width: 38px !important;
  min-height: 38px !important;
  max-width: 38px !important;
  max-height: 38px !important;
  display: inline-block;
}
.modal-content .card_bg {
  border-radius: 30px !important;
  background: transparent !important;
}
.light_theme .modal-content .card_bg:hover {
  box-shadow: 0px 0px 0px 0px #02020299 !important;
}
.link_blue_new {
  color: #446eea !important;
}

.link_blue_new:hover {
  color: #eb9f51 !important;
}

.load_connect_text {
  position: absolute;
  top: 49%;
  left: calc(50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
}
.btn_connect_loader {
  background: transparent !important;
  border: none !important;
  box-shadow: rgb(149 149 187 / 40%) 0px 0px 0px 0px inset !important;
  min-width: 240px !important;
  font-size: 14px !important;
  color: #ff9212 !important;
}
.btn_connect_loader:hover {
  background: transparent !important;
  border: none !important;
  box-shadow: rgb(149 149 187 / 40%) 0px 0px 0px 0px inset !important;
  color: #6ef70e !important;
}
.light_theme .get-started-btn.btn_connect_loader {
  background: none !important;
  color: #f0dffd !important;
}
.light_theme .btn_connect_loader {
  background: none !important;
  color: #6604d3 !important;
}
.table_dropdown_menu .dropdown-item:focus,
.table_dropdown_menu .dropdown-item:hover {
  background-color: #e9ecef1c !important;
}
.table_dropdown_menu.filter_menu.dropdown-menu {
  max-height: 150px !important;
  overflow-y: auto !important;
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  margin: 0px !important;
  transform: translate(0px, 23px) !important;
  display: block !important;
  z-index: 10000 !important;
  opacity: 1 !important;
  pointer-events: initial !important;
}
.table_dropdown_menu.filter_menu.dropdown-menu.d-none {
  display: none !important;
  z-index: 0 !important;
  opacity: 0 !important;
}

.countdown_grey_cen.countdown div {
  font-size: 12px !important;
}
.tokenlistrow:nth-child(2n + 1) {
  background: #222121;
}
.tokenlists h6,
.tokenlists p {
  min-width: 150px;
}
.tokenlistrow {
  width: 550px;
}
.tokenlists h6:last-child,
.tokenlists p:last-child {
  min-width: 100px;
}
.modal .scrolllist {
  height: 400px;
}
.scrolllist {
  overflow-y: auto;
  height: 180px;
  overflow-x: hidden;
}
.tokenlists,
.tokenlists h6 {
  color: #d6d6d6 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

/* NEW CSS */
.admn_form .input-groups .input-group .input-group-text {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.noearp_input_admin .input-group {
  flex-wrap: nowrap !important;
}
.lis_paren_side_admn .lis_paren_side {
  max-width: 180px;
}
/* END NEW CSS */

#loader_div_connect {
  background: #0a0a0a;
  /* / width: calc(100% - 250px); / */
  height: 100%;
  opacity: 0.9;
  position: absolute;
  z-index: 9;
  /* / left:20px; / */
}
@media only screen and (min-width: 992px) {
  #loader_div_connect {
    background: #0a0a0a;
    width: calc(100% - 250px);
  }
  .load_connect_text {
    position: fixed;
    top: 50%;
    left: calc(50% + 125px);
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 1;
  }
  .sidebar_shr #loader_div_connect {
    width: calc(100% - 58px);
    /* / left: calc(50% + 15px); / */
  }
  .sidebar_shr .load_connect_text {
    left: calc(50% + 15px);
  }
}

@media only screen and (max-width: 991px) {
  #loader_div_connect {
    background: #0a0a0a;
    width: calc(100% - 0px);
  }
  .load_connect_text {
    position: fixed;
    top: 50%;
    left: calc(50% - 0px);
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 1;
  }
}

#sidebar {
  z-index: 10 !important;
  position: fixed;
  right: 0;
}

/* NEW CSS */
.admn_form .input-groups .input-group .input-group-text {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.noearp_input_admin .input-group {
  flex-wrap: nowrap !important;
}
.lis_paren_side_admn .lis_paren_side {
  max-width: 180px;
}
/* END NEW CSS */

.table_admin_laun td:nth-child(6),
.table_admin_laun th:nth-child(6) {
  min-width: 130px !important;
}
.table_admin_laun td:nth-child(7),
.table_admin_laun th:nth-child(7) {
  min-width: 100px !important;
}

.table_poricat_list td:nth-child(7),
.table_poricat_list th:nth-child(7) {
  min-width: 130px !important;
}
.table_poricat_list td:nth-child(8),
.table_poricat_list th:nth-child(8) {
  min-width: 100px !important;
}

.table_poricat_list td:nth-child(3),
.table_poricat_list th:nth-child(3) {
  min-width: 130px !important;
}
.link_brk_word {
  word-break: break-all !important;
}

.themeinputgroup {
  display: flex;
}
.themeinputgroup .datepicker_input.input-group {
  border-radius: 6px 0 0 6px !important;
  padding-left: 10px;
}
.themeinputgroup .cur_pointer.input-group-append button {
  border-radius: 0 6px 6px 0;
}
ul.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
}
li.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled:hover {
  color: gray !important;
}
li.react-datepicker__time-list-item:hover {
  color: #fff;
}

.light_theme button[disabled]:hover {
  color: #9b9b9b !important;
}
.light_theme button[disabled] {
  opacity: 0.5 !important;
}
.inut_grp_date_inpt_pad input {
  padding-left: 10px !important;
}

.light_theme #sidebar .accordion .card button {
  color: #000 !important;
}
.light_theme .btn_white_outline_round {
  border-color: #000 !important;
  border: 1px solid #000 !important;
}

.banner_subtitle.form_subhead_1 {
  color: #fff;
  font-size: 16px !important;
  font-weight: 500;
}
.flex_row_cen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.a_brk_new {
  word-break: break-all;
}

.private_sal_size_new {
  min-width: 150px;
}
.btn_social_new_a_new {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.card_bg .card-title {
  /* color: #E79F49; */
  color: #fff;
}
.tab_img .card_bg {
  /* box-shadow: 0px 1px 9px -2px #E79F49 !important; */
  background: #101010 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 6px #4b9a24;
}
.create_lock_date {
  z-index: 2;
}
.light_theme .get-started-btn {
  /* background: #ede5f9 !important; */
  background: transparent linear-gradient(289deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: #fff !important;
  font-weight: 600;
}

.create_modal .tab_img .card_bg {
  box-shadow: none !important;
}
.light_theme .right_side_sec {
  background: #f7f7f7 !important;
}
.light_theme .card_bg .card_img_pare_div {
  /* box-shadow: none  !important; */
  box-shadow: 0px 0px 6px #4b9a24 !important;
}
.light_theme .card_bg:hover .card_img_pare_div {
  box-shadow: 0px 0px 6px #4b9a24 !important;
  background: #fff !important;
}
.light_theme .tab_img .card_bg {
  /* box-shadow:none !important; */
  box-shadow: 0px 1px 16px -1px #5aa52ae0 !important;
  background-color: #fff !important;
}
.login_screen .left_icon {
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.login_screen .right_icon {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.login_screen .center_icon {
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.light_theme .priv_details .card_bg:hover {
  box-shadow: 0px 1px 16px -1px #6903d91f !important;
  background: #f0dffd !important;
}
.light_theme .badge-green-rect {
  /* background: #660acc; */
  background: #4b9a24;
  color: #fff !important;
}
.light_theme .topBar {
  /* background: #ede5f9 !important  ; */
  background: transparent !important;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.2);
}
.light_theme .dropdown_normal_menu a {
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 500;
}

.light_theme .create_modal .tab_img .card_bg {
  box-shadow: none !important;
}
.suite_container {
  position: relative;
}
.suite_container .bg_style2 {
  position: absolute;
  right: 0px;
  top: -25px;
}
.suite_container .bg_style2 img {
  width: 100px;
}
.suite_container .bg_style {
  position: absolute;
  left: -25px;
  top: 25px;
}
.suite_container .bg_style img {
  width: 100px;
}
.suite_container .bg_outline {
  position: absolute;
  right: -50px;
  top: 15%;
}
.suite_container .bg_outline img {
  width: 350px;
}
.grow_sec {
  position: relative;
}
.grow_sec .bg_outline2 {
  position: absolute;
  left: -70px;
  top: 20px;
}
.grow_sec .bg_outline2 img {
  width: 250px;
}
.header_bg {
  position: relative;
}
.header_bg .bgstyle_4 {
  position: absolute;
  left: 60%;
  top: 0px;
}
.header_bg .bgstyle {
  position: absolute;
  left: 30%;
  top: -15px;
}
.take_section {
  position: relative;
}
.take_section .bg_outline1 {
  position: absolute;
  /* right: -50px; */
  right: -50px;

  top: -38px;
}

.coming_sec {
  position: relative;
}

.coming_sec .bg_outline2 {
  position: absolute;
  bottom: 10px;
  left: -55px;
}
.coming_sec .bg_outline2 img {
  width: 250px;
}
.coming_sec .bg_outline1 {
  position: absolute;
  top: 10px;
  right: -55px;
}
.coming_sec .bg_outline1 img {
  width: 250px;
}
.coming_sec .coming_soon {
  position: relative;
}
.coming_sec .coming_soon .bg_style {
  position: absolute;
  left: 30%;
}
.coming_sec .coming_soon .bg_style2 {
  position: absolute;
  left: 70%;
  top: 20%;
}
.coming_sec .coming_soon .bg_style img {
  width: 80px;
}
.coming_sec .coming_soon .bg_style2 img {
  width: 80px;
  filter: invert(1);
}
.take_section .bg_outline1 img {
  width: 300px;
}
.top_heqad_sec.private_sec_bg {
  position: relative;
}
.top_heqad_sec.private_sec_bg .bg_outline1 {
  position: absolute;
  right: -58px;
  top: 20px;
}
.top_heqad_sec.private_sec_bg .bg_outline1 img {
  width: 250px;
}

.disclaimer_sec .bg_outline2 img {
  width: 250px;
}
.kyc_sec {
  position: relative;
}
.kyc_sec .bg_outline1 {
  position: absolute;
  right: 5px;
}
.kyc_sec .bg_outline1 img {
  width: 250px;
}
@media only screen and (max-width: 991px) {
  .take_section .bg_outline1 img {
    width: 150px;
  }
  .take_section .bg_outline1 {
    right: -20px;
    top: 20px;
  }
  .suite_container .bg_outline img {
    width: 250px;
  }
  .suite_container .bg_outline {
    right: -20px;
  }
  .suite_container .bg_style2 img {
    width: 55px;
  }
  .suite_container .bg_style2 {
    top: -50px;
  }
  .suite_container .bg_style img {
    width: 70px;
  }

  .coming_sec .bg_outline1 img {
    width: 150px;
  }
  .coming_sec .bg_outline1 {
    right: -20px;
  }
  .coming_sec .bg_outline2 img {
    width: 150px;
  }
  .top_heqad_sec.private_sec_bg .bg_outline1 img {
    width: 150px;
  }
  .top_heqad_sec.private_sec_bg .bg_outline1 {
    position: absolute;
    right: -20px;
    top: -10px;
  }

  .kyc_sec .bg_outline1 {
    display: none;
  }
}

.light_theme .card_bg .card-title {
  color: #130028;
}
.light_theme .get-started-btn:hover {
  background: transparent !important;
  color: #57a42a !important;
  border: 1px solid #57a42a;
}
.out_light {
  display: none;
}
.light_theme .out_dark {
  display: none;
}
.light_theme .out_light {
  display: block;
}
.light_theme #sidebar {
  box-shadow: none;
}
.light_theme .rounded_btn_wal.get-started-btn {
  /* background: #f0dffd !important; */
  background: transparent linear-gradient(131deg, #9cd947 0%, #4b9a24 100%) 0%
    0% no-repeat padding-box !important;
}

.contain_iomg_mid {
  position: relative;
}
.middile_dark,
.middile_light {
  position: absolute;
  top: 50px;
  left: 50%;
  object-fit: contain;
  width: 400px;
  transform: translate(-50%, 0%);
}

.middile_light {
  display: none;
}

.light_theme .middile_light {
  display: block;
}

.light_theme .middile_dark {
  display: none;
}
.grp_input_style .input-group-text {
  background: #393939;
  border: none;
  margin-right: -5px;
}
.grp_input_style .input-group-text i {
  color: #87cf52;
}
.grp_input_style textarea {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background: #393939 0% 0% no-repeat padding-box;
  border: 1px solid #393939 !important;
}
.grp_input_style textarea:focus {
  background: transparent;
}
.add_airdrops_user .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* NEW CSS */
@media only screen and (max-width: 991px) {
  .lis_paren_side:hover,
  .parent_1_grad.active .lis_paren_side,
  #launchpad_parent.active,
  #privatesale_parent.active,
  #lock_parent.active,
  #airdrop_parent.active,
  #exchangenew_parent.active {
    /* border-radius: 50% !important;
    min-width: 35px !important;
    min-height: 35px !important; */
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink #launchpad_parent.active,
  .side_shrink #privatesale_parent.active,
  .side_shrink #lock_parent.active,
  .side_shrink #airdrop_parent.active,
  .side_shrink #exchangenew_parent.active {
    border-radius: 0px 10px 10px 0px !important;
  }
  .side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side {
    justify-content: flex-start !important;
  }

  .side_shrink .lis_paren_side_accordion:hover .icon_text_div {
    padding-left: 10px !important;
  }
  .lis_paren_side_accordion:hover .icon_text_div {
    /* border-radius: 50% !important;
  min-width: 35px;
  min-height: 35px !important; */
    display: inline-flex;
    align-items: center;
    justify-content: flex-start !important;
    /* padding-left: 0px !important; */
  }
  /* #launchpad_parent.active.pl-2,
#lock_parent.active.pl-2,
#privatesale_parent.active.pl-2,
#airdrop_parent.active.pl-2
{
  padding-left: 0px !important;
} */
  .parent_1_grad.active .lis_paren_side {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  /* #sidebar
{
  right: 0px !important;
  left:unset !important;
} */
  .right_side_sec {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  #sidebar .shrink_side_btn {
    right: 40px !important;
    left: unset !important;
  }
  /* #sidebar .footer_theme_div
{
  left:255px !important;
} */
  #sidebar.side_shrink .shrink_side_btn {
    right: 235px !important;
    left: unset !important;
  }
  #sidebar.side_shrink .footer_theme_div {
    right: unset !important;
    /* left:90px !important; */
    padding-left: 20px !important;
    left: unset !important;
  }
  #sidebar .inner_card_body_acc {
    right: 50px !important;
    left: unset !important;
  }
  .lis_paren_side,
  .parent_1_grad .lis_paren_side,
  #launchpad_parent,
  #privatesale_parent,
  #lock_parent,
  #airdrop_parent,
  #exchangenew_parent {
    min-width: 150px;
    min-height: 30px;
  }
  #sidebar .lis_paren_side_cry {
    margin-left: 15px !important;
  }
  .side_shrink .sidebar_ul {
    padding-right: 10px !important;
  }

  .side_shrink .lis_paren_side:hover,
  .side_shrink .parent_1_grad.active .lis_paren_side,
  .side_shrink #launchpad_parent.active,
  .side_shrink #privatesale_parent.active,
  .side_shrink #lock_parent.active,
  .side_shrink #exchangenew_parent.active,
  .side_shrink #airdrop_parent.active {
    padding-left: 10px !important;
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#lock_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink
    .lis_paren_side_accordion:hover
    .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#launchpad_parent.active,
  #sidebar.side_shrink .icon_text_div#privatesale_parent.active,
  #sidebar.side_shrink .icon_text_div#lock_parent.active,
  #sidebar.side_shrink .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink .icon_text_div#airdrop_parent.active,
  #sidebar.side_shrink .icon_text_div#exchangenew_parent.active,
  #sidebar.side_shrink .lis_paren_side:hover,
  #sidebar.side_shrink .lis_paren_side_accordion:hover .icon_text_div,
  .side_shrink .parent_1_grad.active .lis_paren_side {
    min-width: 35px !important;
    min-height: 35px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 0px !important;
    justify-content: center;
    padding-left: 0px !important;
  }
  #sidebar.side_shrink
    .lis_paren_side_accordion
    .icon_text_div#launchpad_parent,
  #sidebar.side_shrink
    .lis_paren_side_accordion
    .icon_text_div#privatesale_parent,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div#lock_parent,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div#airdrop_parent,
  #sidebar.side_shrink
    .lis_paren_side_accordion
    .icon_text_div#exchangenew_parent,
  #sidebar.side_shrink .icon_text_div#launchpad_parent,
  #sidebar.side_shrink .icon_text_div#privatesale_parent,
  #sidebar.side_shrink .icon_text_div#lock_parent,
  #sidebar.side_shrink .icon_text_div#airdrop_parent,
  #sidebar.side_shrink .icon_text_div#exchangenew_parent,
  #sidebar.side_shrink .lis_paren_side,
  #sidebar.side_shrink .lis_paren_side_accordion .icon_text_div,
  .side_shrink .parent_1_grad .lis_paren_side {
    min-width: 35px !important;
    min-height: 35px !important;
  }
}
#header,
#header1,
#sidebar {
  box-shadow: 4px 1px 5px -2px #37333363 !important;
}
.shrink_side_btn:hover {
  background: #000 !important;
}

.light_theme .shrink_side_btn:hover {
  background: #fff !important;
}

.date_inoput_grps {
  background: #393939 !important;
  border-radius: 6px !important;
}

.light_theme .sidebar_ul .active span {
  color: #fff !important;
}
.light_theme .sidebar_ul .active img {
  filter: unset !important;
}
.shrink_side_btn .fa-chevron-right::before {
  content: "\f053" !important;
}
.side_shrink .shrink_side_btn .fa-chevron-right::before {
  content: "\f054" !important;
}

.sidebar_shr .trendss {
  padding-left: 30px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #b7b4b4 !important;
}

.react-datepicker {
  background-color: #393939 !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  color: #fff !important;
}
.light_theme .react-datepicker {
  background-color: #fff !important;
}
.light_theme .react-datepicker__day-name,
.light_theme .react-datepicker__day,
.light_theme .react-datepicker__time-name,
.light_theme .react-datepicker__current-month,
.light_theme .react-datepicker-time__header,
.light_theme .react-datepicker-year-header,
.light_theme
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  color: #000 !important;
}
.react-datepicker__header,
.react-datepicker__time-container .react-datepicker__time {
  background-color: #000 !important;
}
.light_theme .react-datepicker__header,
.light_theme .react-datepicker__time-container .react-datepicker__time {
  background-color: #f7f7f7 !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #777 !important;
}

.light_theme .react-datepicker__day--disabled,
.light_theme .react-datepicker__month-text--disabled,
.light_theme .react-datepicker__quarter-text--disabled,
.light_theme .react-datepicker__year-text--disabled {
  color: #ccc !important;
}

.text_area_norla_colr.text_are_grp textarea {
  color: #d6d6d6 !important;
  font-size: 12px !important;
  background: #393939 0% 0% no-repeat padding-box;
  border: none !important;
}

@media (max-width: 991px) {
  #sidebar {
    z-index: 89 !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar {
    right: unset !important;
    left: 0px !important;
  }
}

/* NEW HOME CSS */
.banner_left_ttl {
  font-size: 40px;
  color: #5eef16;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.banner_left_hint {
  color: #cbcbcb;
  font-size: 13px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.mainlogo_img {
  max-width: 85px;
  /* height: 50px;
    width: 180px; */
}
.banner_left_dtls {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
.banner_skyblueblur {
  position: absolute;
  top: -15px;
}
.banner_yellowblur {
  position: absolute;
  bottom: -90px;
  left: -270px;
  height: 400px;
  width: 400px;
}

.relative_bitdealts {
  position: relative !important;
  /* overflow: hidden; */
}
.bitdealz_yellowblur {
  position: absolute;
  bottom: -90px;
  right: -250px;
}
.features_yellowblur {
  position: absolute;
  top: -145px;
  left: -270px;
  height: 400px;
  width: 400px;
}
.bitdealts_yellowblur {
  display: none;
  position: absolute;
  top: 50px;
  left: 25%;
  height: 200px;
  width: 200px;
}
.second_yellowblur {
  position: absolute;
  right: -220px;
  height: 300px;
  width: 300px;
}
.why_bitdeals_txt {
  color: #cbcbcb;
  font-weight: 600;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
}
.bitdeals_why {
  color: #4b9a24;
  font-family: "Poppins", sans-serif;
}
.thunder_boldtxt {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0px;
}
.thunder_whole {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.thunder_whole:hover {
  outline: 2px solid #56a130;
  cursor: pointer;
}
.green_hrline {
  border-bottom: 1px solid #56a130;
}
.bottom_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative !important;
}
.footer_yellowblur {
  position: absolute;
  height: 250px;
  right: -194px;
  width: 200px;
}
.social_icons_bottomfoot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -7px;
}
.footer_social_logos {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}
.footer_social_logos:hover,
.light_theme .footer_social_logos:hover {
  color: #56a130;

  cursor: pointer;
}
.copyright_txt.banner_left_hint {
  font-weight: 600 !important;
  margin-left: 50px !important;
}
.light_theme .banner_left_hint,
.light_theme .why_bitdeals_txt,
.light_theme .thunder_boldtxt,
.light_theme .footer_social_logos {
  color: #000;
}
.landing_banner {
  overflow-x: hidden;
}
.light_theme .landing_banner {
  background-image: url("../images/bitdeals/whiterain.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.dark_theme .landing_banner {
  background-image: url("../images/bitdeals/landingbanner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.girl_rocketimg {
  margin-top: -50px;
}
@media (992px < width < 1200px) {
  .girl_rocketimg {
    margin-top: 0px;
  }
}
@media (width < 992px) {
  .copyright_txt.banner_left_hint {
    margin: 0 !important;
  }
}
@media (width < 768px) {
  .banner_toprow {
    margin-top: 30px;
  }
  .row.reverse_row {
    flex-direction: column-reverse;
  }
}

@media (768px < width < 992px) {
  .banner_left_ttl {
    font-size: 30px;
  }
  .thunder_whole {
    padding: 10px;
  }
  .row.reverse_row {
    align-items: center;
  }
}
@media (width < 576px) {
  .bottom_footer {
    display: unset;
  }
  .copyright_txt.banner_left_hint {
    text-align: center;
  }
  .social_icons_bottomfoot {
    justify-content: center;
    margin-top: 20px;
  }
}
@media (width < 450px) {
  .mainlogo_img {
    max-width: 65px;
    /* height: 25px;
    width: 115px; */
  }
  .banner_left_ttl {
    font-size: 30px;
  }
  .copyright_txt.banner_left_hint {
    font-size: 12px;
  }
  .why_bitdeals_txt {
    font-size: 20px;
  }
}

.light_theme .header_top .get-started-btn {
  font-weight: 400 !important;
}
.banner_toprow .get-started-btn,
.banner_toprow .banner_left_hint,
.banner_toprow .banner_left_ttl {
  z-index: 1 !important;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .banner_yellowblur {
    bottom: -190px !important;
  }
}
.kyc_status_div_final {
  text-align: center;
}
.kyc_fianl_text1 {
  color: #9cd947;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.kyc_fianl_text2 {
  color: #d6d6d6 !important;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px !important;
}
.kyc_status_div_final {
  padding: 100px 10px;
}
.row_height_kyc {
  min-height: calc(100vh - 200px);
}

.header_flex.header_flex_new_ad {
  flex-direction: row !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #6c6969 !important;
}

.light_theme
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc !important;
}

.datepicker_input.input-group,
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__triangle {
  left: 0% !important;
}

@media only screen and (min-width: 992px) {
  .react-datepicker__triangle {
    left: -50% !important;
  }
}

@media only screen and (max-width: 422px) {
  .react-datepicker__time-container,
  .react-datepicker__month-container {
    width: 100% !important;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 0% !important;
  }
}

@media only screen and (min-width: 423px) {
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 100px !important;
  }
}

.incon_img_white {
  max-width: 18px;
}

.form_new_banner {
  font-size: 16px !important;
  font-weight: 600;
}

.img_ind_circle_bc {
  /* max-height: unset !important;  */
  min-width: unset !important;
  min-height: unset !important;
}
.bc_grp {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.bc_grp .get-started-btn {
  margin-left: 5px;
  margin-right: 5px;
}

.bc_btn_main_grp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.chart_center_new .apexcharts-canvas {
  margin: 0 auto;
}

@media only screen and (max-width: 575px) {
  .bc_grp {
    display: grid !important;
    grid-template-columns: 33% 33% 33%;
  }
  .bc_grp .get-started-btn {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .bc_grp {
    display: grid !important;
    grid-template-columns: 33% 33% 33%;
  }
  .bc_grp .get-started-btn {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bc_grp .get-started-btn {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.footer_menu_flex_side_right_alog {
  justify-content: flex-end !important;
}

@media only screen and (max-width: 767px) {
  .mainlogo_img {
    max-width: 65px;
    /* height: 25px;
    width: 115px; */
  }
}
.token_add_minw {
  min-width: 120px;
}
.token_add_minw_wrap_addr {
  word-break: break-all;
  white-space: break-spaces;
}

.dropdown_normal_menu_new_co {
  min-width: 145px !important;
}

@media only screen and (min-width: 576px) {
  .dropdown_normal_menu_aco_to.dropdown_normal button {
    min-width: 165px !important;
  }
}

.mainlogo_img_desk_ver {
  max-width: 160px !important;
}

@media only screen and (max-width: 991px) {
  #sidebar .sidebar_ul {
    max-height: unset !important;
  }
  .footer_theme_div {
    padding: 16px !important;
  }
}

@media only screen and (min-width: 992px) {
  #sidebar .sidebar_ul {
    max-height: unset !important;
  }
  .footer_theme_div {
    padding: 16px !important;
  }
  #sidebar.side_shrink .footer_theme_div {
    padding: 16px 8px !important;
  }
}
.lis_paren_side_cry_new_de.lis_paren_side_cry {
  justify-content: flex-start !important;
}

@media only screen and (max-width: 767px) {
  .footer_theme_div {
    padding-bottom: 70px !important;
  }
}

.wallet_icon_coin {
  max-width: 20px !important;
}
.typograp_data {
  font-size: 14px;
  color: #fff;
  line-height: 1.7;
}

.light_theme .typograp_data {
  font-size: 14px;
  color: #000;
}

.iframe_div {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 20px;
}
.iframe_div iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.light_theme .get-started-btn:hover .exch_icon_re_he {
  filter: brightness(0);
}
.ql-editor {
  color: #fff;
}
.light_theme .ql-editor {
  color: #000;
}
.ql-picker-label:hover,
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #50a640 !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #50a640 !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #50a640 !important;
}
.light_theme .affiliate_progrma_tilte {
  color: #000;
}

.affiliate_progrma_tilte {
  color: #fff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 25px;
}
.desc_grey_txt_link_a:hover {
  color: #9cd947 !important;
}
.affilair_table th:first-child,
.affilair_table td:first-child {
  max-width: 20px !important;
  min-width: unset !important;
}

.affilair_table th:nth-child(3),
.affilair_table td:nth-child(3) {
  min-width: 180px !important;
}

.affilair_table th:nth-child(2),
.affilair_table td:nth-child(2) {
  min-width: 150px !important;
}
.overflow_table_Ver {
  max-height: calc(100% - 110px);
  overflow-y: auto;
  padding-right: 20px;
}

.text_row_oneline .desc_grey_txt,
.text_row_oneline > span {
  min-width: 120px;
}
.text_row_oneline > span:last-child,
.text_row_oneline > span:last-child a {
  text-align: right;
}

.text_row_oneline > a {
  text-align: right;
}

.tetx_orange_new {
  color: #efa058 !important;
  font-size: 18px;
}
.tetx_orange_new:hover {
  font-size: 18px;
  color: #54a228 !important;
}

.row_invested_sec .col-12 .text_green .fa-copy {
  color: #efa058 !important;
}

.row_invested_sec .col-12 .text_green .fa-copy:hover {
  color: #54a228 !important;
}

@media only screen and (max-width: 767px) {
  .row_invested_sec .col-12 {
    margin-top: 10px !important;
  }
  .row_invested_sec .col-12 .text_green,
  .tetx_orange_new {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 768px) {
  .row_invested_sec .col-12 .text_green,
  .tetx_orange_new {
    font-size: 18px !important;
  }
}

.copy_icon_tabll .fa-copy {
  color: #efa058 !important;
}
.copy_icon_tabll .fa-copy:hover {
  color: #efa058 !important;
}

.owner_share {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* start of hariharan css */

.tab_scrl_xauto {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  min-height: 60px;
  justify-content: start;
}
.tab_scrl_xauto .nav-link {
  white-space: nowrap !important;
}
.wp_sub_heads {
  color: #fff !important;
  font-weight: 600;
  color: #9cd947 !important;
}
.wp-sub_heads_opt {
  color: #fff !important;
  font-weight: 600;
}
.light_theme .wp-sub_heads_opt {
  color: #000 !important;
}
.wp_grn_hts {
  color: #9cd947 !important;
  font-weight: 600;
}
.wp_h5_itc {
  font-style: italic;
  color: #fff !important;
}
.light_theme .wp_h5_itc {
  color: #000 !important;
}
.card_bg.wp_img_wrp {
  border-radius: 5px !important;
}
.wp_img_wrp img {
  border-radius: 5px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.tab_heads_fix {
  position: fixed !important;
  top: 55px;
  left: 0px;
  z-index: 88;
  background: red;
  padding: 30px;
  background: #0a0a0a !important;
  padding-bottom: 0px !important;
}
.light_theme .tab_heads_fix {
  background: #f7f7f7 !important;
}

.tab_cont_mar_top {
  margin-top: 170px !important;
}
.get-started-btn {
  font-weight: 600;
}
@media (min-width: 768px) {
  .card_bg.wp_img_wrp {
    border-radius: 10px !important;
  }
  .wp_img_wrp img {
    border-radius: 10px;
  }
}
@media (min-width: 992px) {
  .tab_heads_fix {
    position: fixed !important;
    top: 56px;
    left: 257px;
    z-index: 99;

    width: calc(100vw - 257px) !important;
  }
  .sidebar_shr .tab_heads_fix {
    left: 70px;
    width: calc(100vw - 70px) !important;
  }
}
@media (min-width: 1200px) {
  .card_bg.wp_img_wrp {
    border-radius: 15px !important;
  }
  .wp_img_wrp img {
    border-radius: 15px;
  }
}
/* .wp_ul {
  padding-left: 0px !important;
} */
/* end of hariharan css */
